import React, { useEffect, useState, FC, useRef } from 'react';
import { useLoadingContext } from '../../../context/LoadingContext';
import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd';
import update from 'immutability-helper';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ItemType = {
  CATEGORY: 'category',
  SUBCATEGORY: 'subcategory',
};

interface Category {
  id: string;
  name: string;
  subcategories: Subcategory[];
}

interface Subcategory {
  id: string;
  name: string;
}

interface DragItem {
  type: string;
  id: string;
  categoryId?: string;
  index: number;
}

const categoriesData: Category[] = [
  {
    id: 'category1',
    name: 'Category 1',
    subcategories: [
      { id: 'subcategory1', name: 'Subcategory 1' },
      { id: 'subcategory2', name: 'Subcategory 2' },
    ],
  },
  {
    id: 'category2',
    name: 'Category 2',
    subcategories: [
      { id: 'subcategory3', name: 'Subcategory 3' },
      { id: 'subcategory4', name: 'Subcategory 4' },
    ],
  },
];

const Menus: FC = () => {
  const { setLoading } = useLoadingContext();
  const [categories, setCategories] = useState<Category[]>(categoriesData);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // 데이터 로딩 시뮬레이션
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
    };

    fetchData();
  }, [setLoading]);

  const moveSubcategory = (subcategoryId: string, toCategoryId: string) => {
    const sourceCategory = categories.find((category) =>
      category.subcategories.some((sub) => sub.id === subcategoryId)
    );
    const targetCategory = categories.find((category) => category.id === toCategoryId);

    if (sourceCategory && targetCategory && sourceCategory.id !== targetCategory.id) {
      const subcategory = sourceCategory.subcategories.find((sub) => sub.id === subcategoryId);

      if (subcategory) {
        setCategories((prevCategories) =>
          update(prevCategories, {
            [prevCategories.indexOf(sourceCategory)]: {
              subcategories: {
                $splice: [[sourceCategory.subcategories.indexOf(subcategory), 1]],
              },
            },
            [prevCategories.indexOf(targetCategory)]: {
              subcategories: {
                $push: [subcategory],
              },
            },
          })
        );
      }
    }
  };

  const moveCategory = (dragIndex: number, hoverIndex: number) => {
    const dragCategory = categories[dragIndex];
    setCategories((prevCategories) =>
      update(prevCategories, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCategory],
        ],
      })
    );
  };

  const moveSubcategoryWithinCategory = (categoryId: string, dragIndex: number, hoverIndex: number) => {
    const category = categories.find((category) => category.id === categoryId);
    if (category) {
      const dragSubcategory = category.subcategories[dragIndex];
      setCategories((prevCategories) =>
        update(prevCategories, {
          [prevCategories.indexOf(category)]: {
            subcategories: {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragSubcategory],
              ],
            },
          },
        })
      );
    }
  };

  return (
    <div className="p-3">
      <h1 className="text-2xl font-bold mb-4">메뉴 항목 관리</h1>
      <div className="space-y-4 p-3">
        {categories.map((category, index) => (
          <CategoryComponent
            key={category.id}
            index={index}
            category={category}
            moveCategory={moveCategory}
            moveSubcategory={moveSubcategory}
            moveSubcategoryWithinCategory={moveSubcategoryWithinCategory}
          />
        ))}
      </div>
    </div>
  );
};

interface CategoryProps {
  category: Category;
  moveSubcategory: (subcategoryId: string, toCategoryId: string) => void;
  moveCategory: (dragIndex: number, hoverIndex: number) => void;
  moveSubcategoryWithinCategory: (categoryId: string, dragIndex: number, hoverIndex: number) => void;
  index: number;
}

const CategoryComponent: FC<CategoryProps> = ({ category, moveSubcategory, moveCategory, moveSubcategoryWithinCategory, index }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: [ItemType.CATEGORY, ItemType.SUBCATEGORY],
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      if (item.type === ItemType.CATEGORY) {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        moveCategory(dragIndex, hoverIndex);
        item.index = hoverIndex;
      } else if (item.type === ItemType.SUBCATEGORY && item.categoryId !== category.id) {
        moveSubcategory(item.id, category.id);
        item.categoryId = category.id;
        item.index = category.subcategories.length;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.CATEGORY,
    item: { type: ItemType.CATEGORY, id: category.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} className={`bg-white shadow-md rounded-lg p-4 ${isDragging ? 'opacity-50' : ''}`}>
      <CategoryItem category={category} drag={drag} />
      <div className="mt-2 space-y-2">
        {category.subcategories.map((subcategory, subIndex) => (
          <SubcategoryComponent
            key={subcategory.id}
            subcategory={subcategory}
            categoryId={category.id}
            index={subIndex}
            moveSubcategoryWithinCategory={moveSubcategoryWithinCategory}
          />
        ))}
      </div>
    </div>
  );
};

const CategoryItem: FC<{ category: Category; drag: any }> = ({ category, drag }) => {
  const [isGrabbing, setIsGrabbing] = useState(false);

  return (
    <div className="text-lg font-semibold text-gray-800 flex items-center justify-between">
      <div className="flex items-center">
        <div
          ref={drag}
          className="mr-2 text-gray-500 cursor-pointer"
          style={{ cursor: isGrabbing ? 'grabbing' : 'grab' }}
          onMouseDown={() => setIsGrabbing(true)}
          onMouseUp={() => setIsGrabbing(false)}
        >
          <FaBars />
        </div>
        {category.name}
        <span className="text-gray-400 text-sm ml-2 font-normal">{category.id}</span>
      </div>
      <div>
        <a
          href="javascript:;"
          className="bg-gray-700 text-white px-2 py-1 rounded mr-2 text-sm transition duration-300"
        >
          수정
        </a>
        <a
          href="javascript:;"
          className="bg-gray-700 text-white px-2 py-1 rounded text-sm transition duration-300"
        >
          삭제
        </a>
      </div>
    </div>
  );
};

interface SubcategoryProps {
  subcategory: Subcategory;
  categoryId: string;
  index: number;
  moveSubcategoryWithinCategory: (categoryId: string, dragIndex: number, hoverIndex: number) => void;
}

const SubcategoryComponent: FC<SubcategoryProps> = ({ subcategory, categoryId, index, moveSubcategoryWithinCategory }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isGrabbing, setIsGrabbing] = useState(false);

  const [, drop] = useDrop({
    accept: ItemType.SUBCATEGORY,
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      if (item.type === ItemType.SUBCATEGORY && item.categoryId === categoryId) {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        moveSubcategoryWithinCategory(categoryId, dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.SUBCATEGORY,
    item: { type: ItemType.SUBCATEGORY, id: subcategory.id, categoryId, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} className={`ml-4 p-2 bg-gray-50 rounded border border-gray-300 flex items-center justify-between ${isDragging ? 'opacity-50' : ''}`}>
      <div className="flex items-center">
        <div
          ref={drag}
          className="mr-2 text-gray-500 cursor-pointer"
          style={{ cursor: isGrabbing ? 'grabbing' : 'grab' }}
          onMouseDown={() => setIsGrabbing(true)}
          onMouseUp={() => setIsGrabbing(false)}
        >
          <FaBars />
        </div>
        {subcategory.name}
        <span className="text-gray-400 text-sm ml-2">{subcategory.id}</span>
      </div>
      <div>
        <a
          href="javascript:;"
          className="bg-gray-700 text-white px-2 py-1 rounded mr-2 text-sm transition duration-300"
        >
          수정
        </a>
        <a
          href="javascript:;"
          className="bg-gray-700 text-white px-2 py-1 rounded text-sm transition duration-300"
        >
          삭제
        </a>
      </div>
    </div>
  );
};

export default Menus;
