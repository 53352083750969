import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ErrorCode from "../template/ErrorCode";
import IAuthorizeResponse from "../template/IAuthorizeResponse";

const Refresh: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const refreshWorking = useRef(false);

  useEffect(() => {
    const redirect = searchParams.get("redirect") ?? "/";

    const fetchData = async (access: string, refresh: string) => {
      if (refreshWorking.current) return;
      refreshWorking.current = true;

      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/auth/refresh/", {
            method: "POST",
            headers: {
              "authorization": `Bearer ${refresh}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ type: 'web' })
          }
        );
        const data: IAuthorizeResponse = await response.json();
        if (data) {
          const storage = localStorage.getItem("accessToken") ? localStorage : sessionStorage;
          storage.setItem("accessToken", data.accessToken);
          storage.setItem("accessTokenExpiresAt", data.accessTokenExpiresAt.toString());
          storage.setItem("refreshToken", data.refreshToken);
          storage.setItem("refreshTokenExpiresAt", data.refreshTokenExpiresAt.toString());
          navigate(redirect);
        } else {
          navigate(`/logout?redirect=${redirect}`);
        }
      } catch (error) {
        alert("로그인 정보를 갱신 할 수 없습니다.");
        console.error(error);
        navigate(`/logout?redirect=${redirect}`);
      }
    };

    const access = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    const refresh = sessionStorage.getItem("refreshToken") || localStorage.getItem("refreshToken");
    if (access && refresh) fetchData(access, refresh);
    else navigate(`/logout?redirect=${redirect}`);
  }, [navigate, searchParams]);

  return (
    <div className="flex flex-col items-center justify-center my-20 md:mr-20">
      <p className="text-2xl dark:text-white transform transition-300">
        로그인 정보를 갱신 중 입니다. 잠시만 기다려 주세요..
      </p>
    </div>
  );
};

export default Refresh;
