import React from "react";

interface CenterTextProps {
  title: string;
  text: string;
  col?: string;
}

const CenterText: React.FC<CenterTextProps> = ({ title, text, col = "1" }) => (
  <div className={`bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300 min-h-64 md:col-span-${col} flex flex-col`}>
    <h2 className="text-xl font-semibold mb-2">{title}</h2>
    <div className="flex-grow flex items-center justify-center">
      <p className="text-gray-600">{text}</p>
    </div>
  </div>
);

export default CenterText;
