import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const Cover: React.FC = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleCanPlay = () => {
    setIsVideoPlaying(true);
  };

  const handlePlay = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    const video = event.currentTarget;
    if (video.paused) {
      video.play();
    }
  };

  return (
    <div className="relative h-[800px] w-full">
      <img
        className={`absolute inset-0 object-cover w-full h-full ${isVideoPlaying ? 'opacity-0 transition-opacity duration-500' : 'opacity-100 transition-opacity duration-500'}`}
        src="/TempImages/cover.png"
        alt="Cover"
      />
      <video
        className={`absolute inset-0 object-cover w-full h-full ${isVideoPlaying ? 'opacity-100 transition-opacity duration-500' : 'opacity-0 transition-opacity duration-500'}`}
        src="/TempVideos/Main.mp4"
        preload="auto"
        autoPlay
        loop
        muted
        onCanPlay={handleCanPlay}
        onPlay={handlePlay}
      />
      <div className="relative flex items-center justify-center h-full bg-black bg-opacity-60">
        <div className="container mx-auto text-center text-white p-4 mt-10">
          <h2 className="text-md mb-4">Reason for Wish, Jesus Christ</h2>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">소망의 이유, 예수 그리스도</h1>
          <p className="mb-6">우리를 위해 십자가를 지신 예수 그리스도, 바로 우리가 전해야 할 복음입니다.</p>
          <Link to="/about/jesus" className="border border-white text-white py-2 px-4 rounded inline-flex items-center transition duration-300 hover:bg-white hover:text-black">
            교회 소개
            <FaArrowRight className="ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Cover;
