import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import DOMPurify from "dompurify";

import { useLoadingContext } from "../context/LoadingContext";

interface Comment {
  id: number;
  author: string;
  content: string;
  date: string;
}

const mockComments: Comment[] = [
  {
    id: 1,
    author: "User1",
    content: "디자인 테스트용 댓글입니다.",
    date: "2023-05-20",
  },
  {
    id: 2,
    author: "User2",
    content: "댓글은 직접 작성 해 보실 수 있습니다. (저장 X)",
    date: "2023-05-21",
  },
];

const PostView: React.FC = () => {
  const { postNumber } = useParams<{ postNumber: string }>();
  const { setLoading } = useLoadingContext();
  const [postContent, setPostContent] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postAuthor, setPostAuthor] = useState("");
  const [postDate, setPostDate] = useState("");
  const [postViews, setPostViews] = useState(0);
  const [comments, setComments] = useState<Comment[]>(mockComments);
  const [newComment, setNewComment] = useState("");
  const currentUser = "작성자";

  // TODO:
  useEffect(() => {
    const fetchData = async () => {
      // Simulate fetched post data
      const fetchedContent = `<p>${postNumber}번 게시글 입니다.</p>`;
      const sanitizedContent = DOMPurify.sanitize(fetchedContent);
      setPostContent(sanitizedContent);
      setPostTitle(`${postNumber}번 게시글`);
      setPostAuthor("작성자");
      setPostDate("2023-05-19");
      setPostViews(123);
    };

    fetchData();
  }, [setLoading, postNumber]);

  const formatCommentContent = (content: string) => {
    return content
      .replace(/\n{3,}/g, "\n")
      .split("\n")
      .map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
  };

  const handleNewCommentSubmit = () => {
    if (newComment.trim() === "") {
      return;
    }

    const newCommentData = {
      id: comments.length + 1,
      author: currentUser,
      content: newComment,
      date: new Date().toISOString().split("T")[0],
    };
    setComments([...comments, newCommentData]);
    setNewComment("");
  };

  return (
    <>
      <div className="w-full">
        <div className="post-header bg-white dark:bg-gray-800 p-4 rounded-t shadow-md flex justify-between items-center transition duration-300">
          <div>
            <h3 className="text-2xl font-bold dark:text-white transition duration-300">
              {postTitle}
            </h3>
            <div className="text-sm text-gray-600 dark:text-gray-400 transition duration-300">
              <span>{postAuthor}</span> | <span>{postDate}</span> |{" "}
              <span>조회수: {postViews}</span>
            </div>
          </div>
          <div>
            <Link
              to="./../create"
              className="bg-blue-200 text-blue-600 px-2 py-1 rounded mr-2 text-sm dark:bg-blue-700 dark:text-blue-300 transition duration-300"
            >
              수정
            </Link>
            <a
              href="javascript:;"
              className="bg-red-200 text-red-600 px-2 py-1 rounded text-sm dark:bg-red-700 dark:text-red-300 transition duration-300"
            >
              삭제
            </a>
          </div>
        </div>
        <div className="post-content bg-white dark:bg-gray-800 p-4 rounded-b shadow-md mb-4 transition duration-300">
          <div
            dangerouslySetInnerHTML={{ __html: postContent }}
            className="dark:text-white transition duration-300"
          />
          <div className="post-share flex justify-between items-center mt-8 transition duration-300">
            <div>
              <button className="bg-yellow-500 text-white px-4 py-2 rounded mr-2 transition duration-300">
                카카오톡으로 공유
              </button>
            </div>
            <Link
              to="./../"
              className="bg-gray-500 text-white px-4 py-2 rounded transition duration-300"
            >
              게시글 목록
            </Link>
          </div>
        </div>
        <div className="comments-section bg-white dark:bg-gray-800 p-4 rounded shadow-md mb-4 transition duration-300">
          <h4 className="text-xl font-bold mb-4 dark:text-white transition duration-300">
            댓글
          </h4>
          {comments.map((comment) => (
            <div
              key={comment.id}
              className="comment mb-2 transition duration-300"
            >
              <div className="text-sm text-gray-600 dark:text-gray-400 transition duration-300">
                <span>{comment.author}</span> |{" "}
                <span>{comment.date}</span>
              </div>
              <p className="dark:text-white transition duration-300">
                {formatCommentContent(comment.content)}
              </p>
            </div>
          ))}
          <div className="new-comment mt-4">
            <div className="text-sm text-gray-600 dark:text-gray-400 mb-2 transition duration-300">
              {currentUser}
            </div>
            <textarea
              className="w-full p-2 border rounded dark:bg-gray-700 dark:text-white dark:border-gray-500 transition duration-300"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="댓글을 입력하세요."
            />
            <button
              className="bg-green-500 text-white px-4 py-2 rounded mt-2 transition duration-300"
              onClick={handleNewCommentSubmit}
            >
              댓글 등록
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostView;
