import React from 'react';
import Sidebar from '../../components/Dashboard/Sidebar';
import Header from '../../components/Dashboard/Header';
import Footer from '../../components/Dashboard/Footer';

interface DashboardLayoutProps {
  children: React.ReactNode;
  isMenuOpen: boolean;
  toggleMenu: () => void;
  currentSelection: string;
}

const Layout: React.FC<DashboardLayoutProps> = ({ children, isMenuOpen, toggleMenu, currentSelection }) => {
  return (
    <div className="flex flex-col h-screen">
      <div className={`fixed inset-0 bg-black opacity-50 z-40 ${isMenuOpen ? 'block' : 'hidden'} md:hidden`} onClick={toggleMenu}></div>
      <Sidebar isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} currentSelection={currentSelection} />
      <div className="flex flex-col flex-grow md:ml-64 transition-all duration-200">
        <Header toggleMenu={toggleMenu} currentSelection={currentSelection} />
        <div className="flex-grow p-6 overflow-auto">
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
