import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { IMenuSubItems } from "../../template/database/MongoDB/menuItems";

interface SubMenuProps {
  title: string;
  subMenu: string;
  submenuItems: IMenuSubItems[] | null | undefined;
}

const SubMenu: React.FC<SubMenuProps> = ({ title, subMenu, submenuItems }) => {
  const submenuRef = useRef<HTMLDivElement>(null);
  const [submenuHeight, setSubmenuHeight] = useState(0);

  useEffect(() => {
    if (submenuRef.current) {
      setSubmenuHeight(submenuRef.current.scrollHeight);
    }
  });

  return (
    <div
      className="hidden md:block w-64 bg-transparent text-black dark:text-white p-4 pl-12"
      style={{ height: `${submenuHeight}px` }}
      ref={submenuRef}
    >
      <h2 className="text-2xl font-semibold mb-4 text-center transition duration-300">
        {title}
      </h2>
      <ul className="space-y-0">
        {submenuItems
          ? submenuItems?.map((submenu, index) => (
              <li key={index} className="mb-0">
                <Link
                  to={submenu.link}
                  className={`${
                    submenu.name === subMenu
                      ? "bg-blue-400 border-blue-400 text-white dark:bg-white dark:border-white dark:text-black"
                      : "dark:text-white"
                  } block border-l border-r border-t border-gray-400 dark:border-white text-black px-4 py-2 hover:bg-blue-400 hover:border-blue-400 hover:text-white dark:hover:bg-white dark:hover:border-white dark:hover:text-black transition duration-300 rounded-none`}
                  style={{
                    borderTopWidth: index === 0 ? "1px" : "0",
                    borderBottomWidth: "1px",
                    borderTopLeftRadius: index === 0 ? "0.5rem" : "0",
                    borderTopRightRadius: index === 0 ? "0.5rem" : "0",
                    borderBottomLeftRadius:
                      index === submenuItems.length - 1 ? "0.5rem" : "0",
                    borderBottomRightRadius:
                      index === submenuItems.length - 1 ? "0.5rem" : "0",
                  }}
                >
                  {submenu.name}
                </Link>
              </li>
            ))
          : ""}
      </ul>
    </div>
  );
};

export default SubMenu;
