import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="p-4 mt-4 bg-white flex justify-between items-center shadow-footer-top">
      <div className="text-gray-600 text-sm">
        &copy; 2021-2024 <a href="https://meowlabs.kr" target="_blank" rel="noopener noreferrer">MeowLabs</a>.
      </div>
      <div className="flex space-x-4 text-gray-600 text-sm">
        긴급 연락처:&nbsp;<a href="tel:01039665138">010-3966-5138</a>
      </div>
    </footer>
  );
};

export default Footer;
