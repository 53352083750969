import React, { useState, useEffect, useRef } from 'react';

const images = [
  "/TempImages/infoSlide_1.jpg",
  "/TempImages/infoSlide_2.jpg",
];

const About: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(handleNext, 5000);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToIndex = (index: number) => {
    setCurrentIndex(index);
    resetTimer();
  };

  useEffect(() => {
    resetTimer();
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return (
    <div className="flex items-center justify-center p-4 py-16 transition duration-300">
      <div className="flex flex-col md:flex-row w-full max-w-6xl overflow-hidden h-auto md:h-96 relative">
        <div className="w-full md:w-1/2 flex items-center justify-center p-6 rounded-lg relative overflow-hidden pl-8 md:pl-12">
          <div className="relative w-full h-64 md:h-full">
            {images.map((image, index) => (
              <div key={index} className={`absolute inset-0 transition-opacity duration-1000 ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ))}
          </div>
          {images.length > 1 && (
            <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 flex space-x-2">
              {images.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full transition duration-300 ${index === currentIndex ? 'bg-sky-300' : 'bg-gray-400 dark:bg-gray-600'}`}
                  onClick={() => goToIndex(index)}
                />
              ))}
            </div>
          )}
        </div>
        <div className="w-full md:w-1/2 flex flex-col text-black dark:text-gray-200 justify-center p-6 pl-8 md:pl-12">
          <h2 className="text-xl font-bold">그럼에도 불구하고 하나님은 당신을 사랑하십니다.</h2>
          <h1 className="text-4xl font-bold mt-4">하나님과 함께 동행하는 교회</h1>
          <p className="mt-4 text-lg">
            새로운 소외와 그늘이 생겨날 시대정신의 영혼은 깊은 고독 속에서 참된 관계를, 
            혼란으로부터 든든함을, 통제에서 자유를 갈망합니다.
            마음과 마음을 잇고, 교회와 사회의 거리를 잇고 그래서 안식, 평안, 소망을
            누리는 그런 교회 공동체가 되고 싶습니다.
            예수님께서 초대하십니다. 그 소중한 길을 함께 걸어가고 싶습니다...
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
