import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useLoadingContext } from "../context/LoadingContext";

import Navbar from '../components/General/Navbar';
import Footer from '../components/General/Footer';
import Header from "../components/General/Header";
import SubMenu from "../components/General/SubMenu";
import TopButton from '../components/General/TopButton';

import { IMenuItems } from "../template/database/MongoDB/menuItems";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const { setLoading } = useLoadingContext();
  const submenuRef = useRef<HTMLDivElement>(null);

  const [menuItems, setMenuItems] = useState<IMenuItems[] | null>(null);
  const [title, setTitle] = useState<string>('');
  const [subMenu, setSubMenu] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/general/navbar/"
        );
        const data = await response.json();
        setMenuItems(data as IMenuItems[]);
      } catch (error) {
        alert("메뉴 정보를 불러올 수 없습니다.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading]);

  useEffect(() => {
    if (!menuItems) return;

    const findParentAndChild = (path: string) => {
      for (const item of menuItems) {
        for (const subItem of item.subItems) {
          if (subItem.link === path) {
            return { parent: item.name, child: subItem.name };
          }
        }
      }
      return null;
    };

    const data2 = findParentAndChild(location.pathname);
    setTitle(data2?.parent ?? '');
    setSubMenu(data2?.child ?? '');
  }, [location.pathname, menuItems]);

  return (
    <div>
        <Navbar menuItems={menuItems} />
        <div className="relative w-full">
          <Header />
          <div className="flex mb-16 container mx-auto">
            <div ref={submenuRef}>
              <SubMenu
                title={title}
                subMenu={subMenu}
                submenuItems={
                  menuItems?.find((target) => target.name === title)?.subItems
                }
              />
            </div>
            <div className="flex-1 flex flex-col items-center ml-6 mr-6">
              <h2 className="text-4xl font-bold mb-8 dark:text-white transition duration-300">
                {subMenu}
              </h2>
              {children}
            </div>
          </div>
        </div>
        <Footer />
        <TopButton />
    </div>
  );
};

export default Layout;
