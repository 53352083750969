import React, { Suspense } from "react";
import { Routes, Route, Link } from "react-router-dom";

import Root from "./pages/Root";
import MainRouter from "./MainRouter";
import Root_Old from "./pages/Root_Old";
import DashboardRouter from "./DashboardRouter";

const Router: React.FC = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<Root />} />
        <Route path="/old/root" element={<Root_Old />} />
        <Route path="/dashboard/*" element={<DashboardRouter />} />
        <Route path="*" element={<MainRouter />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
