import React from 'react';
import { Link } from 'react-router-dom';
import { FaPrayingHands, FaBookOpen, FaUserFriends, FaArrowRight } from 'react-icons/fa';

const Goals: React.FC = () => {
  return (
    <div className="container mx-auto py-16 mt-8 text-center px-4 sm:px-8">
      <h2 className="text-blue-500 text-sm font-semibold tracking-widest mb-6 uppercase">소망의 이유 예수 그리스도</h2>
      <div className="relative mb-6">
        <h1 className="text-5xl font-extrabold text-gray-800 dark:text-gray-200">2024년 교회 Vision</h1>
        <Link to="/about/vision" className="text-blue-500 font-semibold block mt-4 md:absolute md:top-0 md:right-0 md:mt-3 flex items-center justify-center md:justify-end hover:underline">
          더 알아보기 <FaArrowRight className="ml-2" />
        </Link>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 mt-16">
        <div className="flex flex-col items-center mb-12 bg-white dark:bg-gray-800 p-4 sm:p-8 shadow-lg rounded-lg transition duration-300 transform hover:scale-105">
          <div className="p-6 bg-blue-100 dark:bg-blue-900 rounded-full mb-6 transition duration-300">
            <FaPrayingHands className="text-blue-500 dark:text-blue-300 text-6xl transition duration-300" />
          </div>
          <h2 className="font-bold text-xl mb-4 text-gray-800 dark:text-gray-200">기도로 주님만 바라보는 교회</h2>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            기도는 나의 뜻을 이루기 위한 수단이 아니라, 하나님의 뜻을 받아들이기 위한 통로입니다.
          </p>
        </div>
        <div className="flex flex-col items-center mb-12 bg-white dark:bg-gray-800 p-4 sm:p-8 shadow-lg rounded-lg transition duration-300 transform hover:scale-105">
          <div className="p-6 bg-green-100 dark:bg-green-900 rounded-full mb-6 transition duration-300">
            <FaBookOpen className="text-green-500 dark:text-green-300 text-6xl transition duration-300" />
          </div>
          <h2 className="font-bold text-xl mb-4 text-gray-800 dark:text-gray-200">말씀으로 주님의 뜻이 이루어지는 교회</h2>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            성경은 하나님의 특별계시입니다. 그 특별계시의 중심은 바로 예수 그리스도입니다.
          </p>
        </div>
        <div className="flex flex-col items-center mb-12 bg-white dark:bg-gray-800 p-4 sm:p-8 shadow-lg rounded-lg transition duration-300 transform hover:scale-105">
          <div className="p-6 bg-purple-100 dark:bg-purple-900 rounded-full mb-6 transition duration-300">
            <FaUserFriends className="text-purple-500 dark:text-purple-300 text-6xl transition duration-300" />
          </div>
          <h2 className="font-bold text-xl mb-4 text-gray-800 dark:text-gray-200">성령충만한 삶으로 전도하는 교회</h2>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            성령충만은 예수충만이며, 말씀충만입니다. 성령충만은 삶의 예배자가 가져야 할 예수님의 인품입니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Goals;
