import React, { useState, ComponentType } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight, FaQuestion } from 'react-icons/fa'; // FaQuestion을 기본 아이콘으로 사용
import * as Icons from 'react-icons/fa';

import { menuItems } from '../../storage/dashboard';

type SidebarProps = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  currentSelection: string;
};

const Icon = ({ iconName }: { iconName: string }) => {
  const IconComponent = Icons[iconName as keyof typeof Icons] as ComponentType<any> || FaQuestion;
  return <IconComponent className="mr-3" />;
};

const Sidebar: React.FC<SidebarProps> = ({ isMenuOpen, toggleMenu, currentSelection }) => {
  const initialSectionsState = menuItems.reduce((acc, item) => {
    if (item.sectionKey) {
      acc[item.sectionKey] = true;
    }
    return acc;
  }, {} as { [key in string]: boolean });

  const [sections, setSections] = useState(initialSectionsState);

  const toggleSection = (sectionKey: string) => {
    setSections((prevSections) => ({
      ...prevSections,
      [sectionKey]: !prevSections[sectionKey]
    }));
  };

  return (
    <div className={`bg-white text-gray-900 w-64 h-full fixed flex flex-col shadow-lg overflow-y-auto transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 transition-transform duration-200 ease-in-out z-50 md:z-auto`}>
      <div className="sticky top-0 bg-white z-10">
        <div className="p-4 h-20 flex justify-between items-center">
          <div className="flex justify-center items-center w-full">
            <Link to="/">
              <img src="/Logo_Long.png" alt="Logo" className="block h-10" />
            </Link>
          </div>
        </div>
        <div className="border-t border-gray-200"></div>
      </div>
      <ul className="flex-1 space-y-2 px-4 mb-5 mt-5">
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {item.subItems && item.subItems.length > 0 ? (
              <>
                <div className="p-4 pb-1 text-xs font-semibold text-gray-500 cursor-pointer flex items-center justify-between" onClick={() => toggleSection(item.sectionKey ?? '')}>
                  <span>{item.title}</span>
                  <FaChevronRight className={`transform transition-transform duration-300 ${sections[item.sectionKey ?? ''] ? 'rotate-90' : 'rotate-0'}`} />
                </div>
                <div className={`transition-all duration-300 ease-in-out overflow-hidden ${sections[item.sectionKey ?? ''] ? 'max-h-screen' : 'max-h-0'}`}>
                  {item.subItems.map((subItem, subIndex) => (
                    <li className={`group ${subItem.path === currentSelection ? 'bg-blue-100 text-blue-700 rounded-md' : ''}`} key={subIndex}>
                      <Link to={subItem.path || ''} className="flex items-center p-4 hover:bg-gray-100 rounded-md transition-colors duration-200" onClick={toggleMenu}>
                        <Icon iconName={subItem.icon || 'FaQuestion'} />
                        <span>{subItem.title}</span>
                      </Link>
                    </li>
                  ))}
                </div>
              </>
            ) : (
              <li className={`group ${item.path === currentSelection ? 'bg-blue-100 text-blue-700 rounded-md' : ''}`}>
                <Link to={item.path || ''} className="flex items-center p-4 rounded-md transition-colors duration-200" onClick={toggleMenu}>
                  <Icon iconName={item.icon || 'FaQuestion'} />
                  <span>{item.title}</span>
                </Link>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
