import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Articles: React.FC = () => {
  const truncateTitle = (title: string, maxLength: number) => {
    return title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;
  };

  const ilsanNews = [
    { title: '『영산오케스트라 봉사단원』모집공고', writer: '관리자', pubDate: '2024-01-19', link: '/community/notice/6' },
    { title: '성미가 \'영산종합선물세트\'로 업그레이드 되었습니다!', writer: '관리자', pubDate: '2024-01-13', link: '/community/notice/5' },
    { title: '일산순복음영산교회 대성전 LED 전광판 교체 입찰공고', writer: '관리자', pubDate: '2023-01-06', link: '/community/notice/4' },
    { title: '일산순복음영산교회 로고공모전 심사결과 발표', writer: '관리자', pubDate: '2023-01-06', link: '/community/notice/3' },
    { title: '물품구매 입찰공고(성전내부 교회 카페트 교체 공사)', writer: '관리자', pubDate: '2022-11-04', link: '/community/notice/2' },
    { title: '물품구매(방송장비교체) 입찰공고', writer: '관리자', pubDate: '2022-10-25', link: '/community/notice/1' },
  ];

  const joyfulNews = [
    { title: '건의드립니다', writer: 'shinnabi', pubDate: '2024-04-15', link: '/community/general/6' },
    { title: '3040 아가페선교회 노아의방주 쿠킹클래스', writer: '3040아가페선교회', pubDate: '2024-03-10', link: '/community/general/5' },
    { title: '담임 목사님께 올립니다', writer: '최선용', pubDate: '2023-11-08', link: '/community/general/4' },
    { title: '2023년 추석 가정예배 화일 해상도', writer: '김승태', pubDate: '2023-09-24', link: '/community/general/3' },
    { title: '영상화면에 악보', writer: '박영선', pubDate: '2023-08-06', link: '/community/general/2' },
    { title: '행복한 교회생활', writer: '박영선', pubDate: '2023-04-09', link: '/community/general/1' },
  ];

  const maxTitleLength = window.innerWidth < 640 ? 15 : 23;

  return (
    <div className="container max-w-screen-xl mx-auto p-4 transition duration-300">
      <h1 className="text-4xl font-bold mt-8 mb-10 text-center dark:text-white transition duration-300">커뮤니티</h1>
      <div className="flex flex-col lg:flex-row justify-between mb-12">
        <div className="lg:w-1/2 lg:pr-4 mb-8 lg:mb-0">
          <div className="flex justify-between items-center border-b border-gray-300 dark:border-gray-800 transition duration-300 pb-2 mb-4">
            <h2 className="text-xl font-bold text-blue-600 dark:text-blue-400">교회소식</h2>
            <Link to="/community/notice" className="flex items-center text-gray-400 hover:text-blue-500 dark:text-gray-500 dark:hover:text-blue-300 transition duration-300">
              <span>더보기</span>
              <FaArrowRight className="ml-1" />
            </Link>
          </div>
          <table className="w-full text-left table-fixed">
            <tbody>
              {ilsanNews.map((news, index) => (
                <tr key={index} className="border-b border-gray-300 dark:border-gray-600 transition duration-300">
                  <td className="w-1/2 py-1">
                    <Link to={news.link} className="text-gray-800 hover:text-blue-500 dark:text-gray-200 dark:hover:text-blue-300 transition duration-300">
                      {truncateTitle(news.title, maxTitleLength)}
                    </Link>
                  </td>
                  <td className="w-1/4 py-1">
                    <Link to={news.link} className="text-gray-800 hover:text-blue-500 dark:text-gray-200 dark:hover:text-blue-300 transition duration-300">
                      {news.writer}
                    </Link>
                  </td>
                  <td className="w-1/4 py-1">
                    <Link to={news.link} className="text-gray-800 hover:text-blue-500 dark:text-gray-200 dark:hover:text-blue-300 transition duration-300">
                      {news.pubDate}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="lg:w-1/2 lg:pl-4 mb-4">
          <div className="flex justify-between items-center border-b border-gray-300 dark:border-gray-800 transition duration-300 pb-2 mb-4">
            <h2 className="text-xl font-bold text-blue-600 dark:text-blue-400">자유게시판</h2>
            <Link to="/community/general" className="flex items-center text-gray-400 hover:text-blue-500 dark:text-gray-500 dark:hover:text-blue-300 transition duration-300">
              <span>더보기</span>
              <FaArrowRight className="ml-1" />
            </Link>
          </div>
          <table className="w-full text-left table-fixed">
            <tbody>
              {joyfulNews.map((news, index) => (
                <tr key={index} className="border-b border-gray-300 dark:border-gray-600 transition duration-300">
                  <td className="w-1/2 py-1">
                    <Link to={news.link} className="text-gray-800 hover:text-blue-500 dark:text-gray-200 dark:hover:text-blue-300 transition duration-300">
                      {truncateTitle(news.title, maxTitleLength)}
                    </Link>
                  </td>
                  <td className="w-1/4 py-1">
                    <Link to={news.link} className="text-gray-800 hover:text-blue-500 dark:text-gray-200 dark:hover:text-blue-300 transition duration-300">
                      {news.writer}
                    </Link>
                  </td>
                  <td className="w-1/4 py-1">
                    <Link to={news.link} className="text-gray-800 hover:text-blue-500 dark:text-gray-200 dark:hover:text-blue-300 transition duration-300">
                      {news.pubDate}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Articles;
