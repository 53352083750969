import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(() => {
    if (typeof window !== "undefined") {
      return (
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)
      );
    }
    return false;
  });

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <footer className="bg-sky-700 dark:bg-gray-800 text-white py-6 transition duration-300">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <div className="mb-4 md:mb-0">
            <h1 className="text-2xl font-bold"><Link to="/">일산순복음영산교회</Link></h1>
            <p className="text-sm"><Link to="/about/direction">경기도 고양시 일산동구 성현로431번길 79</Link></p>
            <p className="text-sm">&nbsp;</p>
            <p className="text-sm">
              게시된 모든 콘텐츠의 저작권은 일산순복음영산교회에 있습니다.
            </p>
            <p className="text-sm text-gray-300 dark:text-gray-500 transition duration-300">
              &copy; 2021-2024 <a href="https://meowlabs.kr" target="_blank" rel="noopener noreferrer">MeowLabs</a>.
            </p>
          </div>
          <div className="flex-1 flex flex-col md:flex-row justify-between items-start md:items-center w-full">
            <div className="flex-1 text-left md:text-right md:mr-4">
              <p className="text-sm">
                <Link to="/policy/privacy">
                  개인정보처리방침
                </Link>
              </p>
              <p className="text-sm">
                <Link to="/policy/email">
                  이메일무단수집거부
                </Link>
              </p>
              <p className="text-sm">
                홈페이지 이용문의: <a href="mailto:ilsanfgyc@meowlabs.kr">ilsanfgyc@meowlabs.kr</a>
              </p>
            </div>
            <div className="flex justify-center md:justify-center items-center md:items-center w-full md:w-auto mt-4 md:mt-0">
              <button
                onClick={toggleDarkMode}
                className="h-12 w-12 rounded-lg p-2 hover:bg-gray-300 dark:hover:bg-gray-700 transition duration-300"
              >
                <svg
                  className="fill-white block dark:hidden"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                </svg>
                <svg
                  className="fill-yellow-500 hidden dark:block"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
