import React, { useEffect, lazy, Suspense, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useLoadingContext } from "./context/LoadingContext";

import CreatePost from "./pages/CreatePost";
import PostView from "./pages/PostView";
import SignOut from "./pages/SignOut";
import Refresh from "./pages/Refresh";
import SignIn from "./pages/SignIn";
import Layout from "./pages/Layout";

import { IPages } from "./template/database/MySQL/pages";

const MainRouter: React.FC = () => {
  const { setLoading } = useLoadingContext();
  const [pages, setPages] = useState<IPages[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/general/pages/"
        );
        const data = await response.json();
        setPages(data as IPages[]);
      } catch (error) {
        alert("루트 정보를 불러올 수 없습니다.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading]);

  return (
    <Suspense fallback={null}>
      <Layout>
        <Routes>
          <Route path="/refresh" element={<Refresh />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/logout" element={<SignOut />} />
          {pages?.map((route, index) => {
            const Component = lazy(() => import(`./pages/${route.component}`));
            const componentProps = route.props || {};

            return (
              <React.Fragment key={index}>
                <Route
                  path={route.path}
                  element={<Component {...componentProps} />}
                />
                {(route.component === "GalleryPostView" ||
                  route.component === "ListPostView") && (
                  <>
                    <Route
                      path={`${route.path}/create`}
                      element={
                        <CreatePost />
                      }
                    />
                    <Route
                      path={`${route.path}/:postNumber`}
                      element={
                        <PostView />
                      }
                    />
                  </>
                )}
              </React.Fragment>
            );
          })}
        </Routes>
      </Layout>
    </Suspense>
  );
};

export default MainRouter;
