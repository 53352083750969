import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreatePost: React.FC = () => {
  const [postTitle, setPostTitle] = useState("");
  const [content, setContent] = useState("");

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (postTitle || content) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [postTitle, content]);

  const handlePostTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostTitle(e.target.value);
  };

  const handleContentChange = (value: string) => {
    setContent(value);
  };

  const handleSubmit = () => {
    // 게시글 등록 로직
    console.log("Title:", postTitle);
    console.log("Content:", content);
    setPostTitle("");
    setContent("");
    window.removeEventListener("beforeunload", handleBeforeUnload);
  };

  return (
    <>
      <div className="w-full p-4 bg-white dark:bg-gray-800 shadow-md rounded-lg">
        <h3 className="text-2xl font-semibold mb-4">게시글 작성</h3>
        <input
          type="text"
          value={postTitle}
          onChange={handlePostTitleChange}
          placeholder="제목을 입력하세요"
          className="w-full mb-4 p-2 border border-gray-300 rounded"
        />
        <ReactQuill
          value={content}
          onChange={handleContentChange}
          placeholder="이곳에 내용을 작성하세요. 줄바꿈이 안되는 경우 엔터를 두 번 입력하세요."
          className="mb-4"
        />
        <button
          onClick={handleSubmit}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
        >
          게시글 등록
        </button>
      </div>
    </>
  );
};

export default CreatePost;
