import React, { useEffect, useState } from "react";
import { useLoadingContext } from "../context/LoadingContext";

import Navbar from "../components/General/Navbar";
import FullScreenCarousel from "../components/Root_Old/FullScreenCarousel";
import About from "../components/Root_Old/About";
import Shortcuts from "../components/Root_Old/Shortcuts";
import LatestInfo from "../components/Root_Old/LatestInfo";
import PhotoGallery from "../components/Root_Old/PhotoGallery";
import Articles from "../components/Root_Old/Articles";
import Footer from "../components/General/Footer";
import TopButton from "../components/General/TopButton";
import Modal from "../components/Root_Old/Modal";

import { IMenuItems } from "../template/database/MongoDB/menuItems";

const Root_Old: React.FC = () => {
  const { setLoading } = useLoadingContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationId, setNotificationId] = useState("2024-mission-invite"); // 초기 알림 ID 설정

  useEffect(() => {
    const storedNotificationId = localStorage.getItem("notificationId");
    const dontShowUntil = localStorage.getItem("dontShowUntil");

    if (
      storedNotificationId !== notificationId ||
      (dontShowUntil && new Date(dontShowUntil) <= new Date())
    ) {
      setIsModalOpen(true);
    }
  }, [notificationId]);

  const closeModal = () => setIsModalOpen(false);

  const handleDontShowForADay = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    localStorage.setItem("dontShowUntil", tomorrow.toISOString());
    localStorage.setItem("notificationId", notificationId); // 현재 알림 ID 저장
    setIsModalOpen(false);
  };

  const [menuItems, setMenuItems] = useState<IMenuItems[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/general/navbar/"
        );
        const data = await response.json();
        setMenuItems(data as IMenuItems[]);
      } catch (error) {
        alert("메뉴 정보를 불러올 수 없습니다.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading]);

  return (
    <div>
      <Navbar menuItems={menuItems} />
      <div>
        <FullScreenCarousel />
        <About />
        <Shortcuts />
        <LatestInfo />
        <PhotoGallery />
        <Articles />
      </div>
      <Footer />
      <TopButton />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onDontShowForADay={handleDontShowForADay}
      >
        <h1 className="text-2xl font-bold mb-4 dark:text-white transition duration-300">
          알림
        </h1>
        <img
          src="/TempImages/modal.jpg"
          className="rounded-md"
          alt="2024년 선교사님 초청예배"
        />
      </Modal>
    </div>
  );
};

export default Root_Old;
