import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const landscapeImages = [
  { url: '/TempImages/slide_1.jpg', link: '' },
  { url: '/TempImages/slide_2.jpg', link: '' },
  { url: '/TempImages/slide_3.jpg', link: '' },
];

const portraitImages = [
  { url: '/TempImages/slide_1.jpg', link: '' },
  { url: '/TempImages/slide_2.jpg', link: '' },
  { url: '/TempImages/slide_3.jpg', link: '' },
];

const FullScreenCarousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState(landscapeImages);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleSelect = (index: number) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setImages(portraitImages);
      } else {
        setImages(landscapeImages);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(handleNext, 5000);

    return () => {
      resetTimeout();
    };
  }, [currentIndex, images]);

  return (
    <div className="relative w-full h-screen overflow-hidden transition duration-300">
      <div className="absolute inset-0 flex transition-transform duration-700" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <div key={index} className="w-full h-full flex-shrink-0">
            {image.link ? (
              <Link to={image.link}>
                <img
                  src={image.url}
                  alt={`Slide ${index}`}
                  className="w-full h-full object-cover"
                />
              </Link>
            ) : (
              <img
                src={image.url}
                alt={`Slide ${index}`}
                className="w-full h-full object-cover"
              />
            )}
          </div>
        ))}
      </div>
      <button
        onClick={() => {
          handlePrev();
          resetTimeout();
        }}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white p-2"
      >
        <FaArrowLeft size={48} className="text-white drop-shadow-lg transition duration-300" />
      </button>
      <button
        onClick={() => {
          handleNext();
          resetTimeout();
        }}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white p-2"
      >
        <FaArrowRight size={48} className="text-white drop-shadow-lg transition duration-300" />
      </button>
      <div className="absolute bottom-12 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              handleSelect(index);
              resetTimeout();
            }}
            className={`w-4 h-4 rounded-full transition duration-300 ${
              currentIndex === index ? 'bg-sky-300' : 'bg-gray-400 dark:bg-gray-600'
            }`}
          ></button>
        ))}
      </div>
      <div className="absolute bottom-20 left-1/2 transform -translate-x-1/2 flex flex-col items-center space-y-2">
        <div className="text-white dark:text-gray-300 drop-shadow-lg animate-bounce transition duration-300">아래로 스크롤하세요</div>
      </div>
    </div>
  );
};

export default FullScreenCarousel;
