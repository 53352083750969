import React, { useEffect } from 'react';

import { useLoadingContext } from '../../../context/LoadingContext';

const Articles: React.FC = () => {
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // 데이터 로딩 시뮬레이션
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
    };

    fetchData();
  }, [setLoading]);

  return (
    <p>MAMAGEMENT/ARTICLES</p>
  );
};

export default Articles;
