import React from 'react';
import { Link } from 'react-router-dom';
import { FaUser, FaTv, FaMusic, FaUsers } from 'react-icons/fa';

const LatestInfo: React.FC = () => {
  return (
    <div className="p-4 mt-16 mb-16 transition duration-300">
      <div className="flex flex-col items-center justify-center md:flex-row md:items-start md:justify-center md:space-x-4 max-w-screen-2xl mx-auto">
        <div className="w-full md:w-1/2 lg:w-2/5 mb-4 md:mb-0 md:pr-4 lg:mr-5">
          <div className="relative rounded-lg overflow-hidden">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/WrGKh9oBAvA"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope"
              allowFullScreen
              className="rounded-lg"
            ></iframe>
            <div className="absolute bottom-0 left-0 p-4 bg-black bg-opacity-70 text-white rounded-lg transition duration-300">
              <p>2024-05-12</p>
              <h2 className="text-xl font-bold">주님이 나를 광야로 이끄실 때</h2>
              <p>사 43:19-20 / 남광우 선교사</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/5 md:pl-4 flex flex-wrap">
          <div className="w-1/2 h-40 flex">
            <a
              href="https://www.youtube.com/@youngsanchurch"
              className="bg-red-500 text-white p-4 flex items-center justify-start h-full rounded-tl-lg transition duration-300 w-full"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTv size={35} className="flex-none w-1/4 md:w-1/5" />
              <div className="flex-grow ml-4 w-3/4 md:w-4/5">
                <h3 className="text-lg font-bold">실시간 방송</h3>
                <p>일산순복음영산교회 예배에 함께하세요!</p>
              </div>
            </a>
          </div>
          <div className="w-1/2 h-40 flex">
            <Link
              to="/worship/videos"
              className="bg-blue-500 text-white p-4 flex items-center justify-start h-full rounded-tr-lg transition duration-300 w-full"
            >
              <FaUser size={40} className="flex-none w-1/4 md:w-1/5" />
              <div className="flex-grow ml-4 w-3/4 md:w-4/5">
                <h3 className="text-lg font-bold">설교목록</h3>
                <p>이전 설교 목록</p>
              </div>
            </Link>
          </div>
          <div className="w-1/2 h-40 flex">
            <Link
              to="/youth/group1"
              className="bg-orange-500 text-white p-4 flex items-center justify-start h-full rounded-bl-lg transition duration-300 w-full"
            >
              <FaMusic size={35} className="flex-none w-1/4 md:w-1/5" />
              <div className="flex-grow ml-4 w-3/4 md:w-4/5">
                <h3 className="text-lg font-bold">청년찬양예배</h3>
                <p>청년찬양예배 영상</p>
              </div>
            </Link>
          </div>
          <div className="w-1/2 h-40 flex">
            <Link
              to="/choir/group1"
              className="bg-yellow-500 text-white p-4 flex items-center justify-start h-full rounded-br-lg transition duration-300 w-full"
            >
              <FaUsers size={40} className="flex-none w-1/4 md:w-1/5" />
              <div className="flex-grow ml-4 w-3/4 md:w-4/5">
                <h3 className="text-lg font-bold">찬양대영상</h3>
                <p>전체 찬양대 영상</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestInfo;
