import React, { useEffect } from 'react';
import { useLoadingContext } from '../../context/LoadingContext';

import LineChartComponent from '../../components/Dashboard/Root/LineChart';
import PieChartComponent from '../../components/Dashboard/Root/PieChart';
import CenterText from '../../components/Dashboard/Root/CenterText';

const visitorData = [
  { name: '일', lastWeek: 436, thisWeek: 346 },
  { name: '월', lastWeek: 657, thisWeek: 256 },
  { name: '화', lastWeek: 345, thisWeek: 364 },
  { name: '수', lastWeek: 346, thisWeek: 475 },
  { name: '목', lastWeek: 798, thisWeek: 568 },
  { name: '금', lastWeek: 753, thisWeek: 346 },
  { name: '토', lastWeek: 253, thisWeek: 263 },
];

const newVisitorData = [
  { name: '일', lastWeek: 45, thisWeek: 47 },
  { name: '월', lastWeek: 46, thisWeek: 36 },
  { name: '화', lastWeek: 25, thisWeek: 56 },
  { name: '수', lastWeek: 36, thisWeek: 43 },
  { name: '목', lastWeek: 54, thisWeek: 25 },
  { name: '금', lastWeek: 57, thisWeek: 56 },
  { name: '토', lastWeek: 45, thisWeek: 21 },
];

const webStorageData = [
  { name: '사용중', value: 20 },
  { name: '남은공간', value: 30 },
];

const videoStorageData = [
  { name: '사용중', value: 60 },
  { name: '남은공간', value: 140 },
];

const COLORS = ['#0088FE', '#808080', '#FFBB28', '#FF8042'];

const Root: React.FC = () => {
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // 데이터 로딩 시뮬레이션
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
    };

    fetchData();
  }, [setLoading]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <LineChartComponent data={visitorData} title="일간 방문자" />
      <LineChartComponent data={newVisitorData} title="신규 방문자" />
      <PieChartComponent data={webStorageData} title="웹서버 저장공간" colors={COLORS} />
      <PieChartComponent data={videoStorageData} title="영상서버 저장공간" colors={COLORS} />
      <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300 md:col-span-2 min-h-64">
        <h2 className="text-xl font-semibold mb-2">새가족 신청 목록 (최근 5개)</h2>
        <p className="text-gray-600">WIP.</p>
      </div>
      <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300 md:col-span-2 min-h-64 overflow-auto">
        <h2 className="text-xl font-semibold mb-2">게시글 목록 (최근 5개)</h2>
        <p className="text-gray-600">WIP.</p>
      </div>
      <CenterText title="권한 미보유 카드 디자인 샘플" text="조회 권한이 없습니다." />
    </div>
  );
};

export default Root;
