import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend, TooltipProps } from 'recharts';

type StorageData = {
  name: string;
  value: number;
};

const renderCustomTooltip = (props: TooltipProps<number, string>) => {
  const { payload } = props;
  if (!payload || payload.length === 0) return null;
  return (
    <div className="custom-tooltip bg-white p-2 border border-gray-300 rounded shadow-lg">
      <p className="label">{`${payload[0].name}: ${payload[0].value}GB`}</p>
    </div>
  );
};

const renderCustomLabel = ({ name, value }: { name: string; value: number }) => {
  return `${value}GB`;
};

interface PieChartComponentProps {
  data: StorageData[];
  title: string;
  colors: string[];
  col?: string;
}

const PieChartComponent: React.FC<PieChartComponentProps> = ({ data, title, colors, col = "1" }) => (
  <div className={`bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300 min-h-64 md:col-span-${col}`}>
    <h2 className="text-xl font-semibold mb-2">{title}</h2>
    <div className="w-full h-64">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value"
            label={renderCustomLabel}
            startAngle={90}
            endAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Tooltip content={renderCustomTooltip} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </div>
);

export default PieChartComponent;