import React, { useEffect, useState } from 'react';

interface LoadingSpinnerProps {
  loading: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ loading }) => {
  const [visible, setVisible] = useState(loading);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => setVisible(false), 500); // 디졸브 시간과 동일하게 맞춤
      return () => clearTimeout(timer);
    } else {
      setVisible(true);
    }
  }, [loading]);

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-500 ${
        loading ? 'opacity-100' : 'opacity-0'
      } ${visible ? 'visible' : 'invisible'}`}
    >
      <div className="flex justify-center items-center h-screen">
        <div className="relative inline-flex">
          <div className="w-8 h-8 bg-blue-500 dark:bg-blue-300 rounded-full"></div>
          <div className="w-8 h-8 bg-blue-500 dark:bg-blue-300 rounded-full absolute top-0 left-0 animate-ping"></div>
          <div className="w-8 h-8 bg-blue-500 dark:bg-blue-300 rounded-full absolute top-0 left-0 animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
