import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, TooltipProps } from 'recharts';

type VisitorData = {
  name: string;
  lastWeek: number;
  thisWeek: number;
};

const renderCustomTooltip = (props: TooltipProps<number, string>) => {
  const { payload, label } = props;
  if (!payload || payload.length === 0) return null;
  return (
    <div className="custom-tooltip bg-white p-2 border border-gray-300 rounded shadow-lg">
      <p className="label">{`${label}요일`}</p>
      <p style={{ color: payload[0].color }}>{`지난 주: ${payload[0].value}명`}</p>
      <p style={{ color: payload[1].color }}>{`이번 주: ${payload[1].value}명`}</p>
    </div>
  );
};

interface LineChartComponentProps {
  data: VisitorData[];
  title: string;
  col?: string;
}

const LineChartComponent: React.FC<LineChartComponentProps> = ({ data, title, col = "1" }) => (
  <div className={`bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300 min-h-64 md:col-span-${col}`}>
    <h2 className="text-xl font-semibold mb-2">{title}</h2>
    <div className="w-full h-64">
      <ResponsiveContainer width="99%" height="100%">
        <LineChart data={data} margin={{ top: 5, right: 10, left: -20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={renderCustomTooltip} />
          <Legend />
          <Line type="monotone" dataKey="lastWeek" stroke="#8884d8" name="지난 주" strokeDasharray="3 2" />
          <Line type="monotone" dataKey="thisWeek" stroke="#82ca9d" name="이번 주" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
);

export default LineChartComponent;
