import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";

const TopButton: React.FC = () => {
  const [showTopButton, setShowTopButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowTopButton(true);
    } else {
      setShowTopButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{ zIndex: 1024 }}
      className={`fixed bottom-5 right-5 transition-opacity duration-300 ${
        showTopButton ? "opacity-100" : "opacity-0"
      }`}
    >
      <button
        onClick={scrollToTop}
        className="bg-blue-500 dark:bg-blue-800 transition-opacity duration-300 text-white dark:text-gray-200 p-3 rounded-full shadow-lg flex items-center justify-center"
      >
        <FaArrowUp className="md:mr-2" />
        <span className="hidden md:inline">맨 위로</span>
      </button>
    </div>
  );
};

export default TopButton;
