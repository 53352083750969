import React from "react";
import { BrowserRouter } from "react-router-dom";
import { LoadingProvider, useLoadingContext } from "./context/LoadingContext";
import LoadingSpinner from "./components/General/LoadingSpinner";
import Router from "./Router";
import ScrollToTop from "./components/General/ScrollToTop";
import { useDevToolsStatus } from "./hooks/useDevToolsStatus";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const App: React.FC = () => {
  return (
    <LoadingProvider>
      <DndProvider backend={HTML5Backend}>
        <AppContent />
      </DndProvider>
    </LoadingProvider>
  );
};

const AppContent: React.FC = () => {
  const { loading } = useLoadingContext();
  useDevToolsStatus();

  return (
    <BrowserRouter>
      <LoadingSpinner loading={loading} />
      <ScrollToTop />
      <Router />
    </BrowserRouter>
  );
};

export default App;
