import { useState, useEffect } from 'react';
import devtools from 'devtools-detect';

export function useDevToolsStatus() {
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(devtools.isOpen);

  useEffect(() => {
    const handleChange = (event: any) => {
      setIsDevToolsOpen(event.detail.isOpen);
      if (event.detail.isOpen) {
        console.log('%c잠시만요!', 'color: red; font-weight: bold; font-size: 64px;');
        console.log('%c이 화면은 개발자가 사용하는 화면입니다.', 'font-weight: bold; font-size: 16px;');
        console.log('%c누군가가 이 화면을 열 것을 요청했다면, 즉시 중단하고 개발자에게 문의하세요.', 'font-weight: bold; font-size: 16px;');
        console.log('%c개발자 연락처: CMS(관리페이지) 하단 기재', 'font-weight: bold; font-size: 16px;');
        console.log('%c이 화면을 잘못 사용하면 개인정보가 유출 될 수 있으며, 피해에 대한 책임은 본인에게 있음을 유념하시기 바랍니다.', 'color: red; font-weight: bold; font-size: 16px;');
      }
    };

    window.addEventListener('devtoolschange', handleChange);

    return () => {
      window.removeEventListener('devtoolschange', handleChange);
    };
  }, []);

  return isDevToolsOpen;
}
