import React from "react";

const Header: React.FC = () => {
  return (
    <div className="relative w-full h-2/5 sm:h-2/5 md:h-1/3 lg:h-1/4 xl:h-1/5 overflow-hidden mb-16">
      <img
        src="/TempImages/header.jpg"
        alt="Header"
        className="w-full h-full object-cover"
        style={{
          maskImage: "linear-gradient(to bottom, black 50%, transparent 100%)",
        }}
      />
    </div>
  );
};

export default Header;
