import React, { useEffect, useState } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDontShowForADay: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onDontShowForADay, children }) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      setIsVisible(true);
      document.body.style.overflow = 'hidden'; // 모달이 열릴 때 스크롤 막기
      window.addEventListener('keydown', handleKeyDown);
    } else {
      document.body.style.overflow = 'auto'; // 모달이 닫힐 때 스크롤 가능하게 하기
    }

    return () => {
      document.body.style.overflow = 'auto'; // 컴포넌트가 언마운트될 때 스크롤 가능하게 하기
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300); // 디졸브 효과 시간과 일치
  };

  const handleDontShowForADay = () => {
    setIsVisible(false);
    setTimeout(() => {
      onDontShowForADay();
    }, 300); // 디졸브 효과 시간과 일치
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  if (!isOpen && !isVisible) return null;

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={handleBackdropClick}
    >
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg w-full max-w-lg mx-4 sm:mx-8 relative transition-transform duration-300 max-h-[calc(100vh-2rem)] overflow-hidden">
        <div className="overflow-auto max-h-[calc(100vh-8rem)] p-4">
          {children}
        </div>
        <div className="flex flex-col sm:flex-row justify-between p-4 mt-2 space-y-2 sm:space-y-0 sm:space-x-4">
          <button className="bg-gray-500 dark:bg-gray-700 text-white p-2 rounded transition duration-300" onClick={handleDontShowForADay}>
            하루동안 보이지 않기
          </button>
          <button className="bg-blue-500 dark:bg-blue-700 text-white p-2 rounded transition duration-300" onClick={handleClose}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
