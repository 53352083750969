import React, { useEffect, useState } from "react";
import { useLoadingContext } from "../context/LoadingContext";

import Cover from "../components/Root/Cover";
import Navbar from "../components/General/Navbar";
import Footer from "../components/General/Footer";
import TopButton from "../components/General/TopButton";

import { IMenuItems } from "../template/database/MongoDB/menuItems";
import Goals from "../components/Root/Goals";

const Root: React.FC = () => {
  const { setLoading } = useLoadingContext();

  const [menuItems, setMenuItems] = useState<IMenuItems[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/general/navbar/"
        );
        const data = await response.json();
        setMenuItems(data as IMenuItems[]);
      } catch (error) {
        alert("메뉴 정보를 불러올 수 없습니다.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading]);

  return (
    <div>
      <Navbar menuItems={menuItems} />
      <div>
        <Cover />
        <Goals />
      </div>
      <Footer />
      <TopButton />
    </div>
  );
};

export default Root;
