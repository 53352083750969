import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const photos = [
  {
    id: 1,
    src: 'http://www.ilsanfgyc.or.kr/user/saveDir/board/www52/2136_1715753983_1_thum.jpg',
    title: '어버이주일(5/12)',
    date: '2024-05-15',
    link: '/community/photos/6',
  },
  {
    id: 2,
    src: 'http://www.ilsanfgyc.or.kr/user/saveDir/board/www52/2135_1715605530_21_thum.jpg',
    title: '어린이날 하나다 축제(5/5)',
    date: '2024-05-13',
    link: '/community/photos/5',
  },
  {
    id: 3,
    src: 'http://www.ilsanfgyc.or.kr/user/saveDir/board/www52/2134_1715596821_7_thum.jpg',
    title: '2중교구 연합전도',
    date: '2024-05-13',
    link: '/community/photos/4',
  },
  {
    id: 4,
    src: 'http://www.ilsanfgyc.or.kr/user/saveDir/board/www52/2133_1715150127_12_thum.jpg',
    title: '1중교구 단합대회',
    date: '2024-05-08',
    link: '/community/photos/3',
  },
  {
    id: 5,
    src: 'http://www.ilsanfgyc.or.kr/user/saveDir/board/www52/2126_1714222127_2_thum.jpg',
    title: '새이레 기독학교 초청 예배(4/26)',
    date: '2024-04-27',
    link: '/community/photos/2',
  },
  {
    id: 6,
    src: 'http://www.ilsanfgyc.or.kr/user/saveDir/board/www52/2125_1714211584_3_thum.jpg',
    title: '꽃동산 만들기(4/24)',
    date: '2024-04-27',
    link: '/community/photos/1',
  },
];

const truncateTitle = (title: string, maxLength: number): string => {
  if (title.length > maxLength) {
    return title.slice(0, maxLength) + '...';
  }
  return title;
};

const PhotoGallery: React.FC = () => {
  const maxTitleLength = 20; // 최대 글자 수 설정

  return (
    <div className="min-h-full bg-blue-400 dark:bg-gray-800 p-8 transition duration-300">
      <div className="container mx-auto mb-16 max-w-screen-xl">
        <div className="flex justify-center items-center mb-8 relative">
          <h1 className="text-4xl text-white font-bold">갤러리</h1>
          <Link to="/community/photos" className="absolute right-0 text-white hover:text-gray-300 dark:hover:text-gray-500 transition duration-300 flex items-center">
            <span className="mr-2">더보기</span>
            <FaArrowRight size={20} />
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {photos.map((photo) => (
            <Link
              to={photo.link}
              key={photo.id}
              className="bg-white dark:bg-gray-700 rounded-lg shadow-lg overflow-hidden group transition duration-300"
            >
              <div className="overflow-hidden">
                <img
                  src={photo.src}
                  alt={photo.title}
                  className="w-full h-48 object-cover transform transition-transform duration-300 group-hover:scale-110"
                />
              </div>
              <div className="p-4">
                <h2 className="text-lg font-bold text-gray-900 dark:text-gray-100 transition duration-300">
                  {truncateTitle(photo.title, maxTitleLength)}
                </h2>
                <p className="text-gray-600 dark:text-gray-400 transition duration-300">{photo.date}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
