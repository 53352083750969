import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import Layout from "./pages/Dashboard/Layout";
import Root from "./pages/Dashboard/Root";
import Home from "./pages/Dashboard/Management/Home";
import Popup from "./pages/Dashboard/Management/Popup";
import Menus from "./pages/Dashboard/Management/Menus";
import MenuEdit from "./pages/Dashboard/Management/MenuEdit";
import Articles from "./pages/Dashboard/Management/Articles";
import NewComers from "./pages/Dashboard/ManageUser/NewComers";
import Users from "./pages/Dashboard/ManageUser/Users";
import UserGroups from "./pages/Dashboard/ManageUser/UserGroups";
import _404 from "./pages/Dashboard/404";

import ErrorCode from "./template/ErrorCode";

const DashboardRouter: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [isSignedIn, setSignedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [currentSelection, setCurrentSelection] = useState<string>(location.pathname);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setCurrentSelection(location.pathname);

    const fetchData = async (token: String) => {
      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/auth/validate/", {
            method: "POST",
            headers: {
              "authorization":`Bearer ${token}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({type: 'web'})
          }
        );
        const data = await response.json();
        if ((data as ErrorCode).code === 200) {
          setSignedIn(true);
        } else {
          navigate("/refresh");
        }
      } catch (error) {
        alert("로그인 정보를 불러올 수 없습니다.");
        console.error(error);
      }
    };

    const token = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (token) fetchData(token);
  }, [location, navigate, setSignedIn])

  return (
    <Suspense fallback={null}>
      <Layout isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} currentSelection={currentSelection}>
        <Routes>
          {isSignedIn ? (
            <React.Fragment>
              <Route path="/" element={<Root />} />
              <Route path="/manageWeb/home" element={<Home />} />
              <Route path="/manageWeb/popup" element={<Popup />} />
              <Route path="/manageWeb/menus" element={<Menus />} />
              <Route path="/manageWeb/menuEdit" element={<MenuEdit />} />
              <Route path="/manageWeb/articles" element={<Articles />} />
              <Route path="/manageUser/newComers" element={<NewComers />} />
              <Route path="/manageUser/users" element={<Users />} />
              <Route path="/manageUser/userGroups" element={<UserGroups />} />
            </React.Fragment>
          ) : ''}
          <Route path="*" element={<_404 />} />
        </Routes>
      </Layout>
    </Suspense>
  );
};

export default DashboardRouter;
