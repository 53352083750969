import React, { useEffect, useState } from "react";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";

import { useLoadingContext } from "../context/LoadingContext";

import IAuthorizeResponse from "../template/IAuthorizeResponse";
import ErrorCode from "../template/ErrorCode";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirect = searchParams.get("redirect") ?? "/";

  const [isSignedIn, setSignedIn] = useState(false);
  
  const { setLoading } = useLoadingContext();
  const [userId, setUserId] = useState("");
  const [dob, setDob] = useState("");
  const [remember, setRemember] = useState(false);
  const [dobError, setDobError] = useState("");
  const [userIdError, setUserIdError] = useState("");

  useEffect(() => {
    const fetchData = async (token: String) => {
      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/auth/validate/", {
            method: "POST",
            headers: {
              "authorization":`Bearer ${token}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({type: 'web'})
          }
        );
        const data = await response.json();
        if ((data as ErrorCode).code === 200) {
          setSignedIn(true);
          navigate(redirect)
        }
      } catch (error) {
        alert("로그인 정보를 불러올 수 없습니다.");
        console.error(error);
      }
    };

    const token = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (token) fetchData(token);
  }, [navigate, redirect, setSignedIn])

  const handleDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setDob(value);
    }
  };

  const handleUserIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setUserId(value);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let valid = true;

    if (isSignedIn) {
      alert("이미 로그인 되어 있습니다.");
      valid = false;
    }

    if (!/^\d{8}$/.test(dob)) {
      setDobError("생년월일을 올바르게 입력해주세요. (예시: 20240520)");
      valid = false;
    } else {
      setDobError("");
    }

    if (userId === "") {
      setUserIdError("성도등록번호를 입력해주세요.");
      valid = false;
    } else {
      setUserIdError("");
    }

    if (valid) {
      setLoading(true);

      try {
        const response = await fetch("https://ilsanfgyc-api.meowlabs.kr/v1/auth/signIn", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, birthday: dob, type: 'web' }),
        });

        if (!response.ok) {
          throw new Error(response.json().toString());
        }

        const result: IAuthorizeResponse = await response.json();

        const storage = remember ? localStorage : sessionStorage;
        storage.setItem("accessToken", result.accessToken);
        storage.setItem("accessTokenExpiresAt", result.accessTokenExpiresAt.toString());
        storage.setItem("refreshToken", result.refreshToken);
        storage.setItem("refreshTokenExpiresAt", result.refreshTokenExpiresAt.toString());
        
        navigate(redirect)
      } catch (error) {
        alert("성도등록번호와 생년월일을 확인 해 주세요.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <form
        className="w-full max-w-md bg-white dark:bg-gray-800 p-8 shadow-md rounded-lg transition duration-300"
        onSubmit={handleSubmit}
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 transition duration-300"
            htmlFor="userId"
          >
            성도등록번호
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-900 leading-tight focus:outline-none focus:shadow-outline transition duration-300"
            id="userId"
            type="text"
            placeholder="성도등록번호"
            value={userId}
            onChange={handleUserIdChange}
          />
          {userIdError && (
            <p className="text-red-500 text-xs italic">{userIdError}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 transition duration-300"
            htmlFor="dob"
          >
            생년월일 (YYYYMMDD)
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-900 leading-tight focus:outline-none focus:shadow-outline transition duration-300"
            id="dob"
            type="text"
            value={dob}
            onChange={handleDobChange}
            placeholder="YYYYMMDD"
            maxLength={8}
          />
          {dobError && (
            <p className="text-red-500 text-xs italic">{dobError}</p>
          )}
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2 transition duration-300">
            <input
              className="mr-2 leading-tight"
              type="checkbox"
              checked={remember}
              onChange={(e) => setRemember(e.target.checked)}
            />
            <span className="text-sm">자동 로그인</span>
          </label>
        </div>
        <div className="flex items-center justify-between mb-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
            type="submit"
          >
            로그인
          </button>
          <Link
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
            type="button"
            to="/newcomers/register"
          >
            회원가입
          </Link>
        </div>
        <p className="mt-8 text-center text-gray-600 dark:text-gray-400 transition duration-300">
          로그인 및 회원가입 문의: 관리자
        </p>
      </form>
    </>
  );
};

export default SignIn;
