import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLoadingContext } from '../../../context/LoadingContext';

import ErrorCode from '../../../template/ErrorCode';
import IUsers from '../../../template/database/MySQL/users';
import IGrades from '../../../template/database/MySQL/grades';
import IUsersResponse from '../../../template/IUsersResponse';
import IUserGroups from '../../../template/database/MySQL/userGroups';
import IUserGroupsResponse from '../../../template/IUserGroupsResponse';

const Users: React.FC = () => {
  const { setLoading } = useLoadingContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [users, setUsers] = useState<IUsers[]>([]);
  const [grades, setGrades] = useState<IGrades[]>([]);
  const [groups, setGroups] = useState<IUserGroups[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [sortField, setSortField] = useState<string>('userId');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [searchField, setSearchField] = useState<keyof IUsers>('userId');
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [targetPage, setTargetPage] = useState<number | undefined>(undefined);
  const [selectedAddOption, setSelectedAddOption] = useState<'manual' | 'csv'>('manual');

  const [filteredGroups, setFilteredGroups] = useState<IUserGroups[]>([]);
  const [groupInput, setGroupInput] = useState<string>('');
  const groupInputRef = useRef<HTMLInputElement>(null);

  const [editUserId, setEditUserId] = useState<number>(0);
  const [editUserName, setEditUserName] = useState<string>('');
  const [editGrade, setEditGrade] = useState<string>('');
  const [editBirthday, setEditBirthday] = useState<string>('');
  const [editUserGroup, setEditUserGroup] = useState<string>('');
  const [editLastLoggedIn, setEditLastLoggedIn] = useState<string>('');
  const [editIsEnabled, setEditIsEnabled] = useState<boolean>(true);

  useEffect(() => {
    const fetchGrades = async () => {
      const response = await fetch(
        `https://ilsanfgyc-api.meowlabs.kr/v1/grades/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      const data = await response.json() as IGrades[];
      setGrades(data);
    }
    fetchGrades();
  }, []);

  const fetchUsers = useCallback(async (token: string) => {
    setLoading(true);
    try {
      let url = '';
      let headers: HeadersInit = {
        "authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "Type": "web",
        "Size": itemsPerPage.toString(),
        "Sort-By": sortField,
        "Sort-Order": sortOrder,
      };

      if (searchTerm) {
        url = `https://ilsanfgyc-api.meowlabs.kr/v1/users/search/${searchTerm}`;
        headers = {
          ...headers,
          "Page": currentPage.toString(),
          "Search-By": searchField,
        };
      } else {
        url = `https://ilsanfgyc-api.meowlabs.kr/v1/users/${currentPage}`;
        headers = {
          ...headers,
        };
      }

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const data = await response.json();
      if (data instanceof ErrorCode) {
        alert(`데이터를 불러오지 못했습니다.\n${data.description}: ${data.userDescription}`);
      } else {
        const convertedData = data as IUsersResponse;
        setUsers(convertedData.users);
        setTotalUsers(convertedData.totalLength);
        const pages = Math.floor(totalUsers / itemsPerPage);
        setTotalPages(pages === 0 ? 1 : pages);

        for (const value of convertedData.users) {
          if (groups.findIndex(target => target.id === value.userGroup) < 0) {
            try {
              const response = await fetch(
                `https://ilsanfgyc-api.meowlabs.kr/v1/group/${value.userGroup}`, {
                  method: "GET",
                  headers: {
                    "authorization": `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Type": "web",
                  },
                }
              );
              const data = await response.json();
              if (data instanceof ErrorCode) {
                alert(`데이터를 불러오지 못했습니다.\n${data.description}: ${data.userDescription}`);
              } else {
                const convertedData = data as IUserGroups;
                let previousData = groups;
                previousData.push(convertedData);
                setGroups(previousData);
              }
            } catch {
              alert("데이터를 불러오지 못했습니다.");
            }
          }
        }
      }
    } catch (error) {
      alert('데이터를 불러오지 못했습니다.');
      console.error(error);
      setUsers([]);
      setTotalPages(0);
    }

    setLoading(false);
  }, [currentPage, itemsPerPage, searchField, searchTerm, setLoading, sortField, sortOrder]);

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (!token) return;

    const handler = setTimeout(() => {
      fetchUsers(token);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, currentPage, itemsPerPage, sortField, sortOrder, searchField, fetchUsers]);

  const fetchGroups = useCallback(async (token: string, term: string) => {
    setLoading(true);
    try {
      const response = await fetch(`https://ilsanfgyc-api.meowlabs.kr/v1/groups/search/${term}`, {
        method: "GET",
        headers: {
          "authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Type": "web",
          "Size": "5",
          "Search-By": "displayName",
        },
      });
      const data = await response.json();
      if (data instanceof ErrorCode) {
        alert(`데이터를 불러오지 못했습니다.\n${data.description}: ${data.userDescription}`);
      } else {
        const convertedData = data as IUserGroupsResponse;
        setFilteredGroups(convertedData.groups);
      }
    } catch (error) {
      alert('데이터를 불러오지 못했습니다.');
      console.error(error);
      setFilteredGroups([]);
    }

    setLoading(false);
  }, [setLoading]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setSelectedUser(null);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setSelectedUser(null);
  };

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUser(null);
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleCheckboxChange = (index: number) => {
    setSelectedUser(selectedUser === index ? null : index);
  };

  const handleAdd = () => {
    setSelectedAddOption('manual');
    (document.getElementById('modal_userAdd') as any).showModal();
  };

  const handleEdit = () => {
    if (selectedUser !== null) {
      const user = users[selectedUser];
      setEditUserId(user.userId);
      setEditUserName(user.userName);
      setEditGrade(user.grade.toString());
      const date = new Date(user.birthday);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      setEditBirthday(`${year}-${month}-${day}`);
      setEditUserGroup(groups.find(g => g.id === user.userGroup)?.displayName || user.userGroup.toString());
      if (user.lastLoggedIn) {
        const dt = new Date(user.lastLoggedIn);
        setEditLastLoggedIn(`${dt.getFullYear()}. ${dt.getMonth() + 1}. ${dt.getDay() + 7} ${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}`)
      } else {
        setEditLastLoggedIn('(로그인 기록 없음)');
      }
      setEditIsEnabled(user.isEnabled);

      setIsEditDrawerOpen(true);
    }
  };

  const handleMovePageButton = () => {
    setTargetPage(currentPage);
    (document.getElementById('modal_pageChange') as any).showModal();
  };

  const handleMovePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let page: number | undefined = parseInt(event.target.value);
    if (event.target.value === '') page = undefined;
    else if (Number.isNaN(page)) page = 1;
    else if (page < 1) page = 1;
    else if (page > totalPages) page = totalPages;
    setTargetPage(page);
  }

  const handleMovePage = () => {
    handlePageChange(targetPage ?? 1);
  };

  const handleDeleteButton = () => {
    if (selectedUser !== null) {
      (document.getElementById('modal_userDeleteConfirm') as any).showModal();
    }
  };

  const handleDelete = async () => {
    const token = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (selectedUser === null || !token) return;
    setLoading(true);
    try {
      const response = await fetch(
        `https://ilsanfgyc-api.meowlabs.kr/v1/user/${users[selectedUser].userId}`, {
          method: "DELETE",
          headers: {
            "authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Type": "web",
          }
        }
      );
      const rawData = await response.json();
      if (rawData instanceof ErrorCode) {
        const data = rawData as ErrorCode;
        if (data.code !== 200) alert(`데이터 삭제에 실패했습니다.\n${data.description}: ${data.userDescription}`);
      }
    } catch {
      alert("데이터 삭제에 실패했습니다.");
    }
    fetchUsers(token);
    setLoading(false);
    setSelectedUser(null);
  }

  const handleSort = (field: string) => {
    const order = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
    setSelectedUser(null);
  };

  const getSortIcon = (field: string) => {
    if (sortField === field) {
      return sortOrder === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  const handleManualAdd = async (event: React.FormEvent) => {
    event.preventDefault();
    const token = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (!token) return;

    (document.getElementById('modal_userAddManual') as any).close();

    const formData = new FormData(event.target as HTMLFormElement);
    const userData = {
      birthday: formData.get('birthDay')?.toString().replaceAll("-", ''),
      userName: formData.get('userName'),
      grade: formData.get('grade'),
      userGroup: formData.get('userGroup'),
      isEnabled: formData.get('isEnabled') === 'on',
    };

    setLoading(true);
    try {
      const response = await fetch(
        `https://ilsanfgyc-api.meowlabs.kr/v1/user/${formData.get('userId')}`, {
          method: "POST",
          headers: {
            "authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Type": "web",
          },
          body: JSON.stringify(userData),
        }
      );
      const data = await response.json();
      if (data instanceof ErrorCode) {
        alert(`사용자 추가에 실패했습니다.\n${data.description}: ${data.userDescription}`);
      } else {
        fetchUsers(token);
      }
    } catch {
      alert("사용자 추가에 실패했습니다.");
    }
    setLoading(false);
  };

  const handleXlsxAdd = async (event: React.FormEvent) => {
    event.preventDefault();
    const token = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (!token) return;

    const formData = new FormData(event.target as HTMLFormElement);
    const file = formData.get('xlsxFile');

    if (!file) {
      alert("파일을 선택해 주세요.");
      return;
    }

    (document.getElementById('modal_userAddXlsx') as any).close();

    setLoading(true);
    try {
      const response = await fetch(
        `https://ilsanfgyc-api.meowlabs.kr/v1/users`, {
          method: "POST",
          headers: {
            "authorization": `Bearer ${token}`,
            "Type": "web",
          },
          body: formData,
        }
      );
      const data = await response.json();
      if (data instanceof ErrorCode) {
        alert(`사용자 일괄 추가에 실패했습니다.\n${data.description}: ${data.userDescription}`);
      } else {
        fetchUsers(token);
      }
    } catch {
      alert("사용자 일괄 추가에 실패했습니다.");
    }
    setLoading(false);
  };

  const handleGroupInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setGroupInput(value);
    setEditUserGroup(value);

    const token = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (!token) return;

    if (value === '') {
      setFilteredGroups([]);
    } else {
      const handler = setTimeout(() => {
        fetchGroups(token, value);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  };

  const handleGroupSelect = (groupName: string) => {
    setGroupInput(groupName);
    setEditUserGroup(groupName);
    setFilteredGroups([]);
    if (groupInputRef.current) {
      groupInputRef.current.value = groupName;
    }
  };

  const handleDetailButtonClick = (index: number) => {
    setSelectedUser(index);

    const user = users[index];
    setEditUserId(user.userId);
    setEditUserName(user.userName);
    setEditGrade(user.grade.toString());
    const date = new Date(user.birthday);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    setEditBirthday(`${year}-${month}-${day}`);
    setEditUserGroup(groups.find(g => g.id === user.userGroup)?.displayName || user.userGroup.toString());
    if (user.lastLoggedIn) {
      const dt = new Date(user.lastLoggedIn);
      setEditLastLoggedIn(`${dt.getFullYear()}. ${dt.getMonth() + 1}. ${dt.getDay() + 7} ${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}`)
    } else {
      setEditLastLoggedIn('(로그인 기록 없음)');
    }
    setEditIsEnabled(user.isEnabled);

    setIsEditDrawerOpen(true);
  };

  const handleEditSave = async () => {
    const token = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (!token) return;

    setIsEditDrawerOpen(false);

    const userData = {
      birthday: editBirthday.replaceAll("-", ''),
      userName: editUserName,
      grade: editGrade,
      userGroup: editUserGroup,
      isEnabled: editIsEnabled,
    };

    setLoading(true);
    try {
      const response = await fetch(
        `https://ilsanfgyc-api.meowlabs.kr/v1/user/${editUserId}`, {
          method: "PUT",
          headers: {
            "authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Type": "web",
          },
          body: JSON.stringify(userData),
        }
      );
      const data = await response.json();
      if (data instanceof ErrorCode) {
        alert(`사용자 수정에 실패했습니다.\n${data.description}: ${data.userDescription}`);
      } else {
        fetchUsers(token);
      }
    } catch {
      alert("사용자 수정에 실패했습니다.");
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="overflow-x-auto">
        <div className="flex flex-col md:flex-row justify-between mb-4 gap-4">
          <div className="flex items-center gap-2">
            <select
              className="select select-bordered"
              value={searchField}
              onChange={(e) => setSearchField(e.target.value as keyof IUsers)}
            >
              <option value="userId">성도등록번호</option>
              <option value="userName">이름</option>
              <option value="grade">직분</option>
              <option value="birthday">생년월일</option>
              <option value="userGroup">그룹</option>
            </select>
            <input
              type="text"
              placeholder="검색어 입력"
              className="input input-bordered w-full max-w-xs"
              value={searchTerm}
              onChange={handleSearch}
            />
            <select
              className="select select-bordered"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              defaultValue={25}
            >
              <option value={15}>15개</option>
              <option value={25}>25개</option>
              <option value={50}>50개</option>
            </select>
          </div>
          <div className="flex items-center gap-2">
            <button className="btn btn-primary" onClick={handleAdd}>추가</button>
            <button className="btn btn-secondary" onClick={handleEdit} disabled={selectedUser === null}>수정</button>
            <button className="btn btn-error" onClick={handleDeleteButton} disabled={selectedUser === null}>삭제</button>
          </div>
        </div>
        <table className="table w-full">
          <thead>
            <tr>
              <th></th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('userId')}>성도등록번호 {getSortIcon('userId')}</th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('userName')}>이름 {getSortIcon('userName')}</th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('grade')}>직분 {getSortIcon('grade')}</th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('birthday')}>생년월일 {getSortIcon('birthday')}</th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('userGroup')}>그룹 {getSortIcon('userGroup')}</th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('isEnabled')}>활성화 {getSortIcon('isEnabled')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <th>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={selectedUser === index}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </label>
                </th>
                <td>{user.userId}</td>
                <td>{user.userName}</td>
                <td>{grades[user.grade]?.displayName}</td>
                <td>{new Date(user.birthday).toLocaleDateString()}</td>
                <td>{groups.find(target => target.id === user.userGroup)?.displayName ?? user.userGroup}</td>
                <td>{user.isEnabled ? '활성화' : '비활성화'}</td>
                <th>
                  <button className="btn btn-ghost btn-xs" onClick={() => handleDetailButtonClick(index)}>상세</button>
                </th>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('userId')}>성도등록번호 {getSortIcon('userId')}</th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('userName')}>이름 {getSortIcon('userName')}</th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('grade')}>직분 {getSortIcon('grade')}</th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('birthday')}>생년월일 {getSortIcon('birthday')}</th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('userGroup')}>그룹 {getSortIcon('userGroup')}</th>
              <th className="cursor-pointer hover:underline" onClick={() => handleSort('isEnabled')}>활성화 {getSortIcon('isEnabled')}</th>
              <th></th>
            </tr>
          </tfoot>
        </table>
        <div className="flex-grow flex justify-center mt-4">
          <p className="text-sm text-center">총 {totalUsers}개의 검색 결과가 있습니다.</p>
        </div>
        <div className="flex-grow flex justify-center mt-4">
          <div className="join">
            <button className="join-item btn" disabled={currentPage === 1} onClick={() => handlePageChange(1)}>«</button>
            <button className="join-item btn" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>←</button>
            <button className="join-item btn" onClick={handleMovePageButton}>{currentPage} / {totalPages} 페이지</button>
            <button className="join-item btn" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>→</button>
            <button className="join-item btn" disabled={currentPage === totalPages} onClick={() => handlePageChange(totalPages)}>»</button>
          </div>
        </div>
      </div>
      <dialog id="modal_userAdd" className="modal">
        <div className="modal-box">
        <h3 className="font-bold text-2xl">알림</h3>
          <p className="py-4">다음 두 가지 방법 중 하나를 선택하여 사용자를 추가하세요.</p>
          <div className="collapse collapse-arrow bg-base-200">
            <input type="radio" name="userAddOptions" onChange={() => setSelectedAddOption('manual')} checked={selectedAddOption === 'manual'} />
            <div className="collapse-title text-xl font-medium">1. 수동 입력</div>
            <div className="collapse-content">
              <p>직접 추가할 성도의 데이터를 입력하는 방식입니다.</p>
            </div>
          </div>
          <div className="collapse collapse-arrow bg-base-200 mt-2">
            <input type="radio" name="userAddOptions" onChange={() => setSelectedAddOption('csv')} checked={selectedAddOption === 'csv'} />
            <div className="collapse-title text-xl font-medium">2. 엑셀 템플릿을 활용한 일괄 추가</div>
            <div className="collapse-content">
              <p>제공되는 엑셀 템플릿 파일을 활용하여 여러 성도의 데이터를 일괄 추가하는 방식입니다.</p>
              <p>모바일, 태블릿 환경에서 권장되지 않습니다.</p>
            </div>
          </div>
          <div className="modal-action">
            <form method="dialog">
              <button className="btn mr-3">취소</button>
              <button className="btn btn-primary"
              onClick={() => {
                setGroupInput('');
                (document.getElementById(selectedAddOption === 'manual' ? 'modal_userAddManual' : 'modal_userAddXlsx') as any).showModal();
              }}>계속</button>
            </form>
          </div>
        </div>
      </dialog>
      <dialog id="modal_userAddManual" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-2xl">알림</h3>
          <p className="py-4">추가할 성도의 데이터를 입력하세요.</p>
          <form onSubmit={handleManualAdd} className="space-y-4">
            <div>
              <label htmlFor="userId" className="block text-sm font-medium text-gray-700">성도등록번호</label>
              <input type="text" id="userId" name="userId" className="input input-bordered w-full" required />
            </div>
            <div className="flex space-x-4">
              <div className="w-1/2">
                <label htmlFor="userName" className="block text-sm font-medium text-gray-700">성명</label>
                <input type="text" id="userName" name="userName" className="input input-bordered w-full" required />
              </div>
              <div className="w-1/2">
                <label htmlFor="grade" className="block text-sm font-medium text-gray-700">직분</label>
                <select id="grade" name="grade" className="select select-bordered w-full" required>
                  {grades.map((grade) => (
                    <option key={grade.id} value={grade.id}>{grade.displayName}</option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="birthDay" className="block text-sm font-medium text-gray-700">생년월일</label>
              <input type="date" id="birthDay" name="birthDay" className="input input-bordered w-full" required />
            </div>
            <div className="relative">
                <label htmlFor="userGroup" className="block text-sm font-medium text-gray-700">그룹</label>
                <input
                  type="text"
                  id="userGroup"
                  name="userGroup"
                  className="input input-bordered w-full"
                  value={groupInput}
                  onChange={handleGroupInputChange}
                  ref={groupInputRef}
                  required
                />
                {filteredGroups.length > 0 && (
                  <ul className="absolute z-10 bg-white border border-gray-300 w-full mt-1 rounded-md shadow-lg max-h-48 overflow-auto">
                    {filteredGroups.map((group) => (
                      <li
                        key={group.id}
                        className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleGroupSelect(group.displayName)}
                      >
                        {group.displayName}
                      </li>
                    ))}
                  </ul>
                )}
            </div>
            <div className="form-control">
              <label className="cursor-pointer label">
                <span className="label-text">활성화</span> 
                <input type="checkbox" id="isEnabled" name="isEnabled" className="checkbox" defaultChecked />
              </label>
            </div>
            <div className="modal-action">
              <button type="reset" className="btn mr-3"
                onClick={() => {
                  (document.getElementById('modal_userAddManual') as any).close();
                  (document.getElementById('modal_userAdd') as any).showModal();
                }}>뒤로</button>
              <button type="submit" className="btn btn-primary">추가</button>
            </div>
          </form>
        </div>
      </dialog>
      <dialog id="modal_userAddXlsx" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-2xl">알림</h3>
          <p className="pt-4">엑셀 파일을 업로드하여 여러 성도의 데이터를 일괄 추가하세요.</p>
          <form onSubmit={handleXlsxAdd}>
            <div className="collapse collapse-arrow bg-base-200 my-4">
              <input type="checkbox" />
              <div className="collapse-title text-xl font-medium">파일 업로드 가이드</div>
              <div className="collapse-content">
                <ol className="list-decimal pl-5">
                  <li className="py-1"><p><a href="/files/multipleUserAddForm_20240708.xlsx" download="multipleUserAddForm_20240708.xlsx" className="underline text-blue-500 cursor-pointer">여기</a>를 눌러 템플릿을 다운받습니다.</p><p className="text-sm font-bold">템플릿이 수정되었을 수도 있으니 매번 새 템플릿을 다운받아 작업 해 주세요.</p></li>
                  <li className="py-1"><p>아래와 같은 화면이 보이면, 3번째 줄부터 작성을 시작합니다. <p className="text-sm font-bold">2번째 줄은 예시 줄로, 업로드 시 무시됩니다.</p></p><img className="rounded-lg" alt="sheet" src="/files/multipleUserAddForm_20240708.png" /></li>
                  <li className="py-1"><p>그룹 열은 실제 그룹명과 띄어쓰기까지 정확히 일치해야 합니다. <p className="text-sm font-bold">일치하지 않는 경우, 기본 그룹으로 지정됩니다.</p></p></li>
                  <li className="py-1"><p>직분 열과 활성화 열은 드롭다운을 활용하여 데이터를 입력하는 것을 권장합니다.</p><img className="rounded-lg" alt="dropdown" src="/files/multipleUserAddForm_dropDownGuide.jpg" /></li>
                  <li className="py-1"><p>작성을 마친 파일을 업로드하고, 하단 추가 버튼을 클릭합니다.</p></li>
                  <li className="py-1"><p>서버에서 입력한 내용을 처리하고 결과를 반환합니다. <p className="text-sm font-bold">데이터 규모에 따라 시간이 소요됩니다.</p></p></li>
                </ol>
              </div>
            </div>
            <input type="file" name="xlsxFile" accept=".xlsx" className="file-input file-input-bordered w-full max-w-xs" required />
            <div className="modal-action">
              <button type="reset" className="btn mr-3"
                onClick={() => {
                  (document.getElementById('modal_userAddXlsx') as any).close();
                  (document.getElementById('modal_userAdd') as any).showModal();
                }}>뒤로</button>
              <button type="submit" className="btn btn-primary">추가</button>
            </div>
          </form>
        </div>
      </dialog>
      <div>
        <div className={`fixed inset-0 z-40 bg-black opacity-50 ${isEditDrawerOpen ? 'block' : 'hidden'}`}></div>
        <div className={`fixed inset-0 z-50 flex justify-end ${isEditDrawerOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
          <div className="relative bg-white h-full w-80 p-4 shadow-lg z-10">
            <h2 className="text-xl font-bold mb-4">사용자 수정</h2>
            <div className="py-2 tooltip tooltip-bottom w-full" data-tip="성도등록번호는 수정 할 수 없습니다.">
              <label htmlFor="edit_userId" className="block text-sm font-medium text-gray-700 text-left">성도등록번호</label>
              <input type="text" id="edit_userId" className="input input-bordered w-full" value={editUserId} disabled />
            </div>
            <div className="py-2 flex space-x-4">
              <div className="w-1/2">
                <label htmlFor="edit_userName" className="block text-sm font-medium text-gray-700">성명</label>
                <input type="text" id="edit_userName" className="input input-bordered w-full" value={editUserName} onChange={(e) => setEditUserName(e.target.value)} required />
              </div>
              <div className="w-1/2">
                <label htmlFor="edit_grade" className="block text-sm font-medium text-gray-700">직분</label>
                <select id="edit_grade" className="select select-bordered w-full" value={editGrade} onChange={(e) => setEditGrade(e.target.value)} required>
                  {grades.map((grade) => (
                    <option key={grade.id} value={grade.id}>{grade.displayName}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="py-2">
              <label htmlFor="edit_birthDay" className="block text-sm font-medium text-gray-700">생년월일</label>
              <input type="date" id="edit_birthDay" className="input input-bordered w-full" value={editBirthday} onChange={(e) => setEditBirthday(e.target.value)} required />
            </div>
            <div className="py-2 relative">
              <label htmlFor="edit_userGroup" className="block text-sm font-medium text-gray-700">그룹</label>
              <input type="text" id="edit_userGroup" className="input input-bordered w-full" value={editUserGroup} onChange={handleGroupInputChange} ref={groupInputRef} required />
              {filteredGroups.length > 0 && (
                <ul className="absolute z-10 bg-white border border-gray-300 w-full mt-1 rounded-md shadow-lg max-h-48 overflow-auto">
                  {filteredGroups.map((group) => (
                    <li
                      key={group.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleGroupSelect(group.displayName)}
                    >
                      {group.displayName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="py-2 tooltip tooltip-bottom w-full" data-tip="마지막 로그인 시간은 수정 할 수 없습니다.">
              <label htmlFor="edit_userGroup" className="block text-sm font-medium text-gray-700 text-left">마지막 로그인</label>
              <input type="text" className="input input-bordered w-full" value={editLastLoggedIn} onChange={(e) => setEditLastLoggedIn(e.target.value)} required disabled />
            </div>
            <div className="py-2 form-control">
              <label className="cursor-pointer label">
                <span className="label-text">활성화</span> 
                <input type="checkbox" className="checkbox" checked={editIsEnabled} onChange={(e) => setEditIsEnabled(e.target.checked)} />
              </label>
            </div>
            <div className="text-right">
              <button className="mt-4 btn mr-3" onClick={() => setIsEditDrawerOpen(false)}>취소</button>
              <button className="mt-4 btn btn-primary" onClick={handleEditSave}>수정</button>
            </div>
          </div>
        </div>
      </div>
      <dialog id="modal_pageChange" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-2xl">알림</h3>
          <p className="py-4">이동할 페이지를 입력하세요. (1~{totalPages})</p>
          <input type="text" placeholder={`1~${totalPages}`} value={targetPage} onChange={handleMovePageChange} className="input input-bordered w-full max-w-xs" />
          <div className="modal-action">
            <form method="dialog">
              <button className="btn mr-3">취소</button>
              <button className="btn btn-primary" onClick={handleMovePage}>이동</button>
            </form>
          </div>
        </div>
      </dialog>
      <dialog id="modal_userDeleteConfirm" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-2xl">확인</h3>
          <p className="py-4">정말로 <b>{users[selectedUser ?? 0]?.userName ?? ''} {grades[users[selectedUser ?? 0]?.grade]?.displayName ?? ''}</b> 사용자의 데이터를 삭제하시겠습니까?</p>
          <p className="font-bold">삭제된 데이터는 복구 할 수 없습니다.</p>
          <div className="modal-action">
            <form method="dialog">
              <button className="btn mr-3">취소</button>
              <button className="btn btn-error" onClick={handleDelete}>삭제</button>
            </form>
          </div>
        </div>
      </dialog>
    </React.Fragment>
  );
};

export default Users;
