import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

import { MenuItem, menuItems } from '../../storage/dashboard';
import ErrorCode from '../../template/ErrorCode';
import IUsers from '../../template/database/MySQL/users';
import IGrades from '../../template/database/MySQL/grades';

interface HeaderProps {
  toggleMenu: () => void;
  currentSelection: string;
}

const findTitleByPath = (path: string, items: MenuItem[]): string => {
  for (const item of items) {
    if (item.path === path) {
      return item.title;
    }
    if (item.subItems) {
      const subItem = item.subItems.find(sub => sub.path === path);
      if (subItem) {
        return `${item.title} / ${subItem.title}`;
      }
    }
  }
  return '404 Not Found';
};

const Header: React.FC<HeaderProps> = ({ toggleMenu, currentSelection }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isSignedIn, setSignedIn] = useState(false);
  const [remainingTime, setRemainingTime] = useState('');
  const [userName, setUserName] = useState('');
  const [userGrade, setUserGrade] = useState('');

  const selectedTitle = findTitleByPath(currentSelection, menuItems);

  useEffect(() => {
    const fetchData = async (token: String) => {
      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/auth/validate/", {
            method: "POST",
            headers: {
              "authorization":`Bearer ${token}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({type: 'web'})
          }
        );
        const data = await response.json();
        if ((data as ErrorCode).code === 200) {
          setSignedIn(true);
        } else {
          navigate(`/refresh?redirect=${location.pathname}`);
        }
      } catch (error) {
        alert("로그인 정보를 불러올 수 없습니다.");
        console.error(error);
        navigate(`/refresh?redirect=${location.pathname}`);
        return;
      }

      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/user/me/", {
            method: "GET",
            headers: {
              "authorization":`Bearer ${token}`,
              "Content-Type": "application/json",
              "Type": "web"
            }
          }
        );
        const data = await response.json() as IUsers;

        const response2 = await fetch(
          `https://ilsanfgyc-api.meowlabs.kr/v1/grade/${data.grade}/`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        const data2 = await response2.json() as IGrades;
        
        setUserName(data.userName);
        setUserGrade(data2.displayName);
      } catch (error) {
        alert("유저 정보를 불러올 수 없습니다.");
        console.error(error);
      }
    };

    const token = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (token) fetchData(token);
  }, [location.pathname, navigate, setSignedIn]);

  useEffect(() => {
    const calculateRemainingTime = () => {
      const expiresAt = sessionStorage.getItem('accessTokenExpiresAt') || localStorage.getItem('accessTokenExpiresAt');
      if (!expiresAt) return;

      const now = Math.floor(Date.now() / 1000);
      const secondsLeft = Number(expiresAt) - now;

      if (secondsLeft <= 0) {
        navigate(`/refresh?redirect?=${location.pathname}`);
        return;
      }

      const days = Math.floor(secondsLeft / (24 * 60 * 60));
      const hours = Math.floor((secondsLeft % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((secondsLeft % (60 * 60)) / 60);
      const seconds = secondsLeft % 60;

      let timeString = '';
      if (days > 0) timeString += `${days}일 `;
      if (hours > 0) timeString += `${hours}시간 `;
      if (minutes > 0) timeString += `${minutes}분 `;
      if (seconds > 0) timeString += `${seconds}초`;

      setRemainingTime(timeString.trim());
    };

    calculateRemainingTime();
    const interval = setInterval(calculateRemainingTime, 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className="bg-white shadow-header-bottom p-5 h-20 flex-shrink-0 flex items-center justify-between relative z-10">
      <div className="flex items-center">
        <button className="text-gray-900 md:hidden mr-4 text-2xl" onClick={toggleMenu}>
          <FaBars />
        </button>
        <h1 className="text-2xl font-semibold">{selectedTitle}</h1>
      </div>
      <div className="flex items-center hidden md:block">
        {remainingTime && (
          <div className="flex items-center">
            <b>{remainingTime}</b>&nbsp;뒤 페이지가 새로고침됩니다.&nbsp;<Link to={`/refresh?redirect=${location.pathname}`} className="underline text-blue-500 cursor-pointer">새로고침(연장)</Link>
          </div>
        )}
      </div>
      <div className="flex items-center">
        {isSignedIn ? (
          <React.Fragment>
            <p className="hidden md:block"><b>{userName}</b> {userGrade}(으)로 로그인 되었습니다.</p>
            <p className="block md:hidden"><b>{userName}</b> {userGrade}</p>
            <Link to="/logout" className="ml-3 bg-gray-500 text-white px-3 py-2 rounded-md">
              로그아웃
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className="hidden md:block"><b>로그아웃</b> 상태입니다.</p>
            <p className="block md:hidden"><b>로그아웃</b> 상태</p>
            <Link to={`/login?redirect=${location.pathname}`} className="ml-3 bg-blue-500 text-white px-3 py-2 rounded-md">
              로그인
            </Link>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Header;
