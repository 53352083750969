import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ErrorCode from "../template/ErrorCode";

const SignOut: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const redirect = searchParams.get("redirect") ?? "/";

    const fetchData = async (access: string) => {
      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/auth/signOut/", {
            method: "POST",
            headers: {
              "authorization":`Bearer ${access}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({type: 'web'})
          }
        );
        const data = await response.json();
        console.log(data)
      } catch (error) {
        alert("로그인 정보를 불러올 수 없습니다.");
        console.error(error);
      } finally {
        sessionStorage.removeItem("accessToken")
        sessionStorage.removeItem("accessTokenExpiresAt")
        sessionStorage.removeItem("refreshToken")
        sessionStorage.removeItem("refreshTokenExpiresAt")
        localStorage.removeItem("accessToken")
        localStorage.removeItem("accessTokenExpiresAt")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("refreshTokenExpiresAt")

        navigate(redirect);
      }
    };

    const access = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (access) fetchData(access);
    else navigate(redirect)
  }, [navigate])


  return (
    <div className="flex flex-col items-center justify-center my-20 md:mr-20">
      <p className="text-2xl dark:text-white transform transition-300">
        로그아웃 중 입니다. 잠시만 기다려 주세요..
      </p>
    </div>
  );
};

export default SignOut;
