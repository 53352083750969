import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaHome } from 'react-icons/fa';

import { useLoadingContext } from '../../context/LoadingContext';

const _404: React.FC = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // 데이터 로딩 시뮬레이션
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
    };

    fetchData();
  }, [setLoading]);

  return (
    <div className="flex items-center justify-center min-h-full">
      <div className="flex flex-col items-center justify-center mt-20 mb-20">
        <h1 className="text-6xl font-bold dark:text-white transform transition-300">
          404
        </h1>
        <p className="text-2xl dark:text-white transform transition-300">
          Page Not Found
        </p>
        <p className="dark:text-white transform transition-300">
          존재하지 않는 페이지 입니다.
        </p>
        <div className="mt-4 flex space-x-2">
          <button
            className="bg-blue-500 dark:bg-blue-700 text-white py-2 px-4 rounded flex items-center justify-center transform transition-300"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeft className="mr-2" />
            이전 페이지
          </button>
          <Link
            to="/dashboard"
            className="bg-green-500 dark:bg-green-700 text-white py-2 px-4 rounded flex items-center justify-center transform transition-300"
          >
            <FaHome className="mr-2" />
            홈으로
          </Link>
        </div>
      </div>
    </div>
  );
};

export default _404;
