import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Shortcuts.css";

interface Service {
  id: number;
  name: string;
  description: string;
  icon: string;
  link: string;
}

const services: Service[] = [
  {
    id: 1,
    name: "교회소개",
    description: "우리 교회는요?",
    icon: "FaChurch",
    link: "/about/vision",
  },
  {
    id: 2,
    name: "교회주보",
    description: "교회주보",
    icon: "FaNewspaper",
    link: "/worship/weekly",
  },
  {
    id: 3,
    name: "새가족등록",
    description: "모두 환영합니다",
    icon: "FaIdCard",
    link: "/newcomers/register",
  },
  {
    id: 4,
    name: "예배안내",
    description: "예배시간, 장소 안내",
    icon: "FaInfo",
    link: "/worship/information",
  },
  {
    id: 5,
    name: "행사일정",
    description: "각종 행사 안내",
    icon: "FaRegCalendarAlt",
    link: "/worship/schedule",
  },
  {
    id: 6,
    name: "온라인헌금",
    description: "헌금창구 안내",
    icon: "FaDonate",
    link: "/administration/donation",
  },
];

const Shortcuts: React.FC = () => {
  const [loadedIcons, setLoadedIcons] = useState<{
    [key: string]: React.ComponentType<any>;
  }>({});
  
  const shortcutItemStyle = {
    width: "140px",
    height: "140px",
    opacity: 0
  };

  useEffect(() => {
    const loadIcons = async () => {
      const iconModules = await Promise.all(
        services.map(async (service) => {
          const iconModule = await import("react-icons/fa").then(
            (module) => module[service.icon as keyof typeof module]
          );
          return { [service.icon]: iconModule as React.ComponentType<any> };
        })
      );
      const iconsMap = iconModules.reduce(
        (acc, cur) => ({ ...acc, ...cur }),
        {}
      );
      setLoadedIcons(iconsMap);
    };
    loadIcons();
  }, []);

  return (
    <div className="flex flex-col items-center p-4 bg-blue-400 dark:bg-gray-800 transition duration-300">
      <div className="flex flex-wrap justify-center items-center gap-y-4 mt-16 mb-16">
        {services.map((service, index) => {
          const IconComponent = loadedIcons[service.icon];
          return (
            <Link
              to={service.link}
              key={service.id}
              className={`shortcut-item flex flex-col items-center justify-center m-4 p-4 bg-white dark:bg-gray-700 rounded-full shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-110`}
              style={{ ...shortcutItemStyle, animationDelay: `${index * 0.2}s` }}
            >
              {IconComponent ? (
                <IconComponent className="text-3xl text-blue-500 dark:text-blue-300 transition duration-300" />
              ) : (
                <div className="text-3xl text-blue-500 dark:text-blue-300 transition duration-300">...</div>
              )}
              <span className="mt-2 text-center font-bold text-md text-gray-900 dark:text-gray-100 transition duration-300">
                {service.name}
              </span>
              <p className="mt-1 text-center text-xs text-gray-600 dark:text-gray-300 transition duration-300">
                {service.description}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Shortcuts;
