import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IMenuItems } from "../../template/database/MongoDB/menuItems";
import ErrorCode from "../../template/ErrorCode";

interface NavbarProps {
  menuItems: IMenuItems[] | null;
}

const Navbar: React.FC<NavbarProps> = ({ menuItems }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isSignedIn, setSignedIn] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [dropdownHeight, setDropdownHeight] = useState("0px");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [hamburgerDropdownHeight, setHamburgerDropdownHeight] = useState("0px");
  const [hamburgerDropdownVisible, setHamburgerDropdownVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const hamburgerDropdownRef = useRef<HTMLDivElement>(null);
  const buttonRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const hideTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchData = async (token: String) => {
      try {
        const response = await fetch(
          "https://ilsanfgyc-api.meowlabs.kr/v1/auth/validate/", {
            method: "POST",
            headers: {
              "authorization":`Bearer ${token}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({type: 'web'})
          }
        );
        const data = await response.json();
        if ((data as ErrorCode).code === 200) {
          setSignedIn(true);
        } else {
          if (location.pathname !== '/refresh' && location.pathname !== '/logout') navigate(`/refresh?redirect=${location.pathname}`);
        }
      } catch (error) {
        alert("로그인 정보를 불러올 수 없습니다.");
        console.error(error);
      }
    };

    const token = sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    if (token) fetchData(token);
  }, [location.pathname, navigate, setSignedIn])

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleMouseEnter = (menu: string) => {
    if (window.innerWidth >= 768) {
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current);
      }
      if (isAnimating) {
        setDropdownHeight("0px");
        setIsAnimating(true);
        setTimeout(() => {
          setDropdownVisible(true);
          setActiveDropdown(menu);
          setIsAnimating(false);
          setTimeout(() => {
            const height = dropdownRef.current?.scrollHeight;
            const maxHeight = window.innerHeight - 64; // Adjusting for the navbar height (64px)
            setDropdownHeight(
              height ? `${Math.min(height, maxHeight)}px` : "0px"
            );
          }, 10);
        }, 300);
      } else {
        setActiveDropdown(menu);
        setDropdownVisible(true);
        setTimeout(() => {
          const height = dropdownRef.current?.scrollHeight;
          const maxHeight = window.innerHeight - 64; // Adjusting for the navbar height (64px)
          setDropdownHeight(
            height ? `${Math.min(height, maxHeight)}px` : "0px"
          );
        }, 10);
      }
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth >= 768) {
      hideTimeout.current = setTimeout(() => {
        setDropdownHeight("0px");
        setIsAnimating(true);
        setTimeout(() => {
          setDropdownVisible(false);
          setActiveDropdown(null);
          setIsAnimating(false);
        }, 300); // 애니메이션 지속 시간과 일치시킴
      }, 100); // 마우스 이벤트 발생 시 딜레이를 줌
    }
  };

  const handleHamburgerToggle = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      setHamburgerDropdownVisible(true);
      setTimeout(() => {
        const height = hamburgerDropdownRef.current?.scrollHeight;
        const maxHeight = window.innerHeight - 64; // Adjusting for the navbar height (64px)
        setHamburgerDropdownHeight(
          height ? `${Math.min(height, maxHeight)}px` : "0px"
        );
      }, 10);
    } else {
      setHamburgerDropdownHeight("0px");
      setTimeout(() => {
        setHamburgerDropdownVisible(false);
      }, 300); // 애니메이션 지속 시간과 일치시킴
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setMenuOpen(false);
      setHamburgerDropdownHeight("0px");
      setHamburgerDropdownVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current);
      }
    };
  }, []);

  const getNavbarClasses = () => {
    if (scrolled) {
      return "rounded-t-none rounded-b-lg";
    }
    if (menuOpen) {
      return "rounded-t-lg rounded-b-none";
    }
    return "rounded-lg";
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  return (
    <>
      {isSignedIn ? (
        <div className="relative z-50 bg-gray-800 text-white text-center">
          <Link to="/dashboard">
            관리자 페이지로 이동
          </Link>
        </div>
      ) : ''}
      <div
        className={`fixed top-0 w-full z-40 transition-all duration-300 ease-in-out ${
          scrolled ? "p-0" : "p-5"
        }`}
      >
        <div
          className={`relative bg-white dark:bg-gray-800 transition-all duration-300 ease-in-out ${getNavbarClasses()}`}
        >
          <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16">
            <div className="flex items-center justify-between h-full">
              <div className="flex-shrink-0">
                <Link to="/" onClick={enableScroll}>
                  <img className="h-8" src="/Logo_Long.png" alt="Logo" />
                </Link>
              </div>
              <div className="hidden md:flex space-x-4">
                {menuItems?.map((menu) => (
                  <div
                    key={menu.name}
                    ref={(el) => (buttonRefs.current[menu.name] = el)}
                    className={`relative group ${
                      menu.showInNavbar ? "block" : "hidden"
                    }`}
                    onMouseEnter={() => handleMouseEnter(menu.name)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      to="#"
                      onClick={enableScroll}
                      className={`${
                        activeDropdown === menu.name
                          ? "text-blue-500"
                          : "text-gray-800 dark:text-gray-300"
                      } transition duration-300 flex items-center h-full py-6 text-center`}
                    >
                      {menu.name.charAt(0).toUpperCase() + menu.name.slice(1)}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="hidden md:flex space-x-4">
                {isSignedIn ? (
                <Link
                  to={`/logout?redirect=${location.pathname}`}
                  onClick={enableScroll}
                  className="bg-gray-500 text-white dark:bg-blue-600 dark:text-gray-200 px-3 py-2 rounded-md transition duration-300"
                >
                  로그아웃
                </Link>
                ) : (
                  <Link
                    to={`/login?redirect=${location.pathname}`}
                    onClick={enableScroll}
                    className="bg-blue-500 text-white dark:bg-blue-600 dark:text-gray-200 px-3 py-2 rounded-md transition duration-300"
                  >
                    로그인
                  </Link>
                )}
              </div>
              <div className="md:hidden flex items-center">
                <button
                  onClick={handleHamburgerToggle}
                  className="text-gray-800 dark:text-gray-300 focus:outline-none"
                >
                  <svg
                    className={`w-6 h-6 transition-transform duration-300 ${
                      menuOpen ? "transform rotate-90" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {menuOpen ? (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    ) : (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16m-7 6h7"
                      ></path>
                    )}
                  </svg>
                </button>
              </div>
            </div>
          </nav>
          {hamburgerDropdownVisible && (
            <div
              ref={hamburgerDropdownRef}
              className={`md:hidden bg-white dark:bg-gray-800 px-2 pt-2 pb-3 space-y-1 sm:px-3 transition-all duration-300 ease-in-out overflow-y-auto absolute top-full left-0 right-0 rounded-b-lg`}
              style={{
                maxHeight: hamburgerDropdownHeight,
                transition: "max-height 0.3s ease-in-out",
                display: hamburgerDropdownVisible ? "block" : "none",
              }}
              onMouseEnter={disableScroll}
              onMouseLeave={enableScroll}
            >
              {menuItems?.map((menu) => (
                <div key={menu.name} className="block">
                  <Link
                    to="#"
                    onClick={enableScroll}
                    className={`text-gray-800 dark:text-gray-300 hover:text-blue-500 font-semibold transition duration-300 mt-8 ${
                      menu.showInNavbar ? "block" : "hidden"
                    }`}
                  >
                    {menu.name.charAt(0).toUpperCase() + menu.name.slice(1)}
                  </Link>
                  <div className="ml-4">
                    {menu.subItems?.map((submenu, index) => (
                      <Link
                        key={index}
                        to={submenu.link}
                        onClick={enableScroll}
                        className={`text-gray-600 dark:text-gray-400 hover:text-blue-500 transition duration-300 mt-2 ${
                          (menu.showInNavbar || submenu.showInNavbar) ? "block" : "hidden"
                        }`}
                      >
                        {submenu.name}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
              <div className="flex space-x-4">
                {isSignedIn ? (
                <Link
                  to={`/logout?redirect=${location.pathname}`}
                  onClick={enableScroll}
                  className="bg-gray-500 dark:bg-blue-600 text-white dark:text-gray-200 px-3 py-2 mt-4 rounded-md transition duration-300"
                >
                  로그아웃
                </Link>
                ) : (
                  <Link
                    to={`/login?redirect=${location.pathname}`}
                    onClick={enableScroll}
                    className="bg-blue-500 dark:bg-blue-600 text-white dark:text-gray-200 px-3 py-2 mt-4 rounded-md transition duration-300"
                  >
                    로그인
                  </Link>
                )}
              </div>
            </div>
          )}
          {dropdownVisible && activeDropdown && (
            <div
              ref={dropdownRef}
              className="hidden md:flex justify-center bg-white dark:bg-gray-800 px-4 pt-2 pb-3 space-y-1 transition-all duration-300 ease-in-out overflow-y-auto absolute top-full rounded-b-lg z-30"
              style={{
                maxHeight: dropdownHeight,
                transition: "max-height 0.3s ease-in-out",
                left: buttonRefs.current[activeDropdown]?.offsetLeft ?? 0, // 기본값 0으로 설정
                width: buttonRefs.current[activeDropdown]?.offsetWidth
                  ? `${buttonRefs.current[activeDropdown]!.offsetWidth * 3}px`
                  : "auto", // 기본값 auto로 설정
              }}
              onMouseEnter={() => {
                if (hideTimeout.current) {
                  clearTimeout(hideTimeout.current);
                }
                setIsAnimating(false);
                disableScroll();
              }}
              onMouseLeave={() => {
                hideTimeout.current = setTimeout(() => {
                  setDropdownHeight("0px");
                  setIsAnimating(true);
                  setTimeout(() => {
                    setDropdownVisible(false);
                    setActiveDropdown(null);
                    setIsAnimating(false);
                    enableScroll();
                  }, 300);
                }, 100); // 마우스 이벤트 발생 시 딜레이를 줌
              }}
            >
              <div className="space-y-1 w-full">
                {menuItems
                  ?.find((target) => target.name === activeDropdown)
                  ?.subItems.map((submenu, index) => (
                    <Link
                      key={index}
                      to={submenu.link}
                      onClick={enableScroll}
                      className={`${(submenu.showInNavbar) ? "block" : "hidden"} px-4 py-2 text-gray-800 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-500 transition duration-300 text-sm`}
                    >
                      {submenu.name}
                    </Link>
                  ))}
                <p>&nbsp;</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
