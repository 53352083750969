interface MenuItem {
  title: string;
  icon?: string;
  path?: string;
  sectionKey?: string;
  subItems?: MenuItem[];
}

const menuItems: MenuItem[] = [
  {
    title: "홈",
    icon: "FaHome",
    path: "/dashboard",
    sectionKey: "home",
  },
  {
    title: "통계",
    sectionKey: "analytics",
    subItems: [
      {
        title: "사이트 통계",
        icon: "FaChartBar",
        path: "/dashboard/analytics/website",
      },
      {
        title: "시스템 현황",
        icon: "FaSignal",
        path: "/dashboard/analytics/system",
      },
    ],
  },
  {
    title: "웹페이지 관리",
    sectionKey: "manageWeb",
    subItems: [
        {
          title: "팝업 관리",
          icon: "FaRegWindowRestore",
          path: "/dashboard/manageWeb/popup",
        },
        {
          title: "홈 수정",
          icon: "FaPencilRuler",
          path: "/dashboard/manageWeb/home",
        },
      {
        title: "메뉴 항목 관리",
        icon: "FaThList",
        path: "/dashboard/manageWeb/menus",
      },
      {
        title: "메뉴 수정",
        icon: "FaPencilAlt",
        path: "/dashboard/manageWeb/menuEdit",
      },
      {
        title: "게시글 관리",
        icon: "FaFileAlt",
        path: "/dashboard/manageWeb/articles",
      },
    ],
  },
  {
    title: "회원 관리",
    sectionKey: "manageUser",
    subItems: [
      {
        title: "새가족 신청 관리",
        icon: "FaClipboardList",
        path: "/dashboard/manageUser/newcomers",
      },
      {
        title: "개별 사용자 관리",
        icon: "FaUser",
        path: "/dashboard/manageUser/users",
      },
      {
        title: "사용자 그룹 관리",
        icon: "FaUsers",
        path: "/dashboard/manageUser/userGroups",
      },
    ],
  },
  {
    title: "서버 관리",
    sectionKey: "manageServer",
    subItems: [
      {
        title: "웹서버 관리",
        icon: "FaHdd",
        path: "/dashboard/manageServer/web",
      },
      {
        title: "영상서버 관리",
        icon: "FaServer",
        path: "/dashboard/manageServer/video",
      },
      {
        title: "데이터베이스 관리",
        icon: "FaDatabase",
        path: "/dashboard/manageServer/database",
      },
    ],
  },
];

export { menuItems };
export type { MenuItem };
